
<header><h1><strong>{title}</strong></h1></header>

<div class="date">
{date.getUTCFullYear()}
— 
{date.getUTCMonth() + 1} 
— 
{date.getUTCDate()} 
</div>

<script lang="ts">
  export let title: string = ""
  export let date: Date
</script>
<style lang="scss">
  .date{
    margin-right: 1rem;
  }

</style>
