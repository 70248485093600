<!-- OpenGraph description: about -->

<main>
	<Bar />
		<div class="description">
			<div>
				Hey, I’m Pètar. I'm a musician, coder and visual artist.
			</div>
			<wbr />
			
			<wbr />
			<div>
				Feel free to hit me up on discord/email.
				You can find me at:
			</div>
			<wbr />
			<div>
				<p>
					petar.guglev — at gee mail
				</p>
				<gbr/>
				<p>
					rita.wighter — discord
				</p>
				<gbr/>
				<p>
					<a href="https://twitter.com/wrightwrighter">
						wrightwrighter — twitter
					</a>
				</p>
				<gbr/>
				<p>
					<a href="https://github.com/wrightwriter">
						wrightwriter — github
					</a>
				</p>
				<gbr/>
				<p>
					<a href="https://www.shadertoy.com/user/jeyko/sort=newest">
						jeyko — shadertoy
					</a>
				</p>
			</div>

		</div>
		<Footer />
</main>



<script lang="ts">
	import {onMount} from 'svelte'
	import {onDestroy} from 'svelte'

	import Bar from "./utils/NavBar.svelte"
	import Footer from './utils/Footer.svelte'

	
	onDestroy(() => {})
</script>

<style lang="scss">
	gbr {
		height: 1rem !important;
		line-height: 5rem !important;
	}
	.name{
		font-weight: bolder;
		font-size: 2rem;
		margin-bottom: 1.0rem;
		margin-top: 0.9rem;
	}
	.gallery{
		display: flex;
		flex-wrap: wrap;
	}
	.description{
		margin-top: 3rem;
		display: flex;
		flex-direction: column;
		font-weight: var(--text-font-weight);
		a {
			font-weight: var(--link-font-weight);
		}
		*{
			font-weight: var(--text-font-weight);
			font-size: 1.0rem;
		}
	}
	main {
		width: 100%;
		max-width: 40rem;
		min-height: 100%;
		height: fit-content;
		// overflow: scroll;
		display: flex;
		flex-direction: column;
		max-width: var(--main-max-width);
		canvas {
			display: none;
			width: 100%;
			height: 100%;
			// display: block;
			margin: auto;
			padding: 0;
		}
	}
</style>


