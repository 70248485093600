
<img 
  class="lozad" 
  data-src={`${ window.LFS_PREPEND }${ src }`} 
  style="width: inherit;"
/>

<script lang="ts">
  export let src: string = ""
</script>
