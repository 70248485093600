// import '../public/Petrona-VariableFont_wght.ttf'
// import '../public/Petrona-Italic-VariableFont_wght.ttf'

import App from './components/App.svelte'

window.is_dev = process.env.NODE_ENV === 'development'
// window.is_dev = false
// window.LFS_PREPEND = 'https://media.githubusercontent.com/media/wrightwriter/wrightwriter.github.io/master/'
// window.LFS_PREPEND = is_dev ? '' : 'https://media.githubusercontent.com/media/wrightwriter/wrightwriter.github.io/master/'
window.RESOURCES = RESOURCES
window.BLOG_POSTS = BLOG_POSTS
window.DARK_MODE = false

const app = new App({
	target: document.body,
	props: {},
})

export default app
