<svelte:head>
	{#if is_dark_mode_toggled}
	<style>
		*{
			--accent-light: black;
			--accent-dark: white;
		}
		pre {
			filter: invert(1);
		}
		code * {
			font-weight: 800 !important;
		}
		pre *::selection{
			/* background: black !important; */
			/* filter: invert(1) !important; */
			background: #7c7c7c;
		}
		.track{
			background: white !important;
			filter: invert(1) !important;
		}
		.track *{
			/* color: */
			--accent-light: white;
			--accent-dark: black;
		}
		svg {
			fill: var(--accent-dark);

		}
		#toggle-container svg {
			background: var(--accent-light);
			fill:var(--accent-dark);
			/* filter: invert(1);
			&:active{
				filter: invert(0) !important;
			} */
		} 
			
	</style>
	{:else}
	<style>
		*{
			--accent-light: white;
			--accent-dark: black;
		}
		

	</style>
	{/if}

</svelte:head>


<div id="toggle-container" bind:this={container_element}>
	{@html LightDarkToggleIcon}
	
</div>

<script lang="ts">
	import {onMount} from 'svelte'
	import {onDestroy} from 'svelte'
	
	
	import LightDarkToggleIcon from '/../public/brightness_6_FILL0_wght400_GRAD0_opsz48.svg'
	import { dark_mode } from 'store'
	
	const systemSettingDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
	
	let is_dark_mode_toggled = localStorage.getItem("dark-mode") ? true : false
	dark_mode.set(is_dark_mode_toggled)
	
	let container_element: HTMLElement
	let light_dark_toggle_icon_element: SVGSVGElement
	
	// let style_media: string = ""
	$: style_media = is_dark_mode_toggled ? "" : `max-width:1px`
	
	// function refresh_styl
	

	onMount(async () => {
		light_dark_toggle_icon_element = container_element.querySelector("svg") as SVGSVGElement
		light_dark_toggle_icon_element.onclick = ()=>{
			is_dark_mode_toggled = !is_dark_mode_toggled
			dark_mode.set(is_dark_mode_toggled)
			localStorage.setItem("dark-mode", is_dark_mode_toggled ? "true" : "")
		}
	})

	onDestroy(() => {})
</script>

<style lang="scss">
#toggle-container {
	margin-top: auto;
	width: 2rem;
	margin-right: auto;
	margin-left: 1rem;

	:global(svg){
		cursor: pointer;
		aspect-ratio: 1/1;
		width: 2rem;
		&:active{
			filter: invert(1);
			background: var(--accent-light);
		}
	}

}
</style>

