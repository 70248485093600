
<a href={link} class="menu-item {add_classes}">
  {@html text}
</a>
<script lang="ts">
	import {onMount} from 'svelte'
	import {onDestroy} from 'svelte'

	const is_dev = process.env.NODE_ENV === 'development'
  export let text: string
  export let link: string
  if (is_dev){
    link += ".html"
  }

	$: path_name = location.pathname 
  // $: add_classes = link.includes(path_name) || link.replace('.html', '').includes(path_name) ? " selected" : ""
  // $: add_classes = link.replace('.html', '').includes(path_name) ? " selected" : ""
  $: add_classes = path_name.includes(link.replace('.html', '')) ? " selected" : ""
  $: {
    console.log(path_name)
    console.log(link)
  }
	


	onMount(async () => {
	})

	onDestroy(() => {})
</script>

<style lang="scss">
  :global(.menu-item, .menu-item *){
    font-weight: 750 !important;
    font-style: italic;
    width: fit-content;
    // background: none;
    color: var(--accent-dark);
    // &:hover{
    //   filter: invert(1);
    // }
  }
  :global(.selected, .selected *) {
    background: var(--accent-dark);
    color: var(--accent-light) !important;
  }
</style>
