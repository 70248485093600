<footer id="bar">
	<div id="name">
		Petar Guglev © 2023
	</div>
</footer>

<script lang="ts">
	import {onDestroy} from 'svelte'
	
	
	onDestroy(() => {})
</script>

<style lang="scss">
#bar {
	#name{
		margin-right: 2rem;
	}
	width: 100%;
	margin-top: auto;
	// margin-bottom: 0rem;

	// padding-right: 5rem;
	// padding-left: 5rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
	// padding: 2rem;
	// margin-top: 2rem;
	// margin-bottom: 2rem;
	*{
		font-weight: 600;
	}
	padding-top: 2rem;
	padding-bottom: 2rem;
	// margin-left: 10rem;
	// margin-right: auto;
}
</style>
