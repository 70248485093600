<svelte:head>
	<link rel="icon" type="image/png" href={`${window.LFS_PREPEND}/favicon-32.png`} sizes="32x32" />
	<link rel="icon" type="image/png" href={`${window.LFS_PREPEND}/favicon-128.png`} sizes="128x128" />
</svelte:head>
<div style="width: 100%; height: 100%;">
	{#if path_name === "/favdemos" || path_name === "/favdemos.html" }
		<FavDemos/>
	{:else if path_name === "/music" || path_name === "/music.html" }
		<Music/>
	{:else if path_name === "/favexegfx" || path_name === "/favexegfx.html" }
		<ExeGfx/>
	{:else if path_name === "/art" || path_name === "/art.html" }
		<Art/>
	{:else if path_name === "/projects" || path_name === "/projects.html" }
		<Projects/>
	{:else if path_name === "/about" || path_name === "/about.html" }
		<About/>
	{:else if path_name === "/blog" || path_name === "/blog.html" }
		<Blog/>
	{:else if path_name.includes("/blog") }
<!-- WEBPACK_INSERT_BLOG_POSTS -->
<!-- svelte-ignore empty-block -->
{#if false}
{:else if path_name === "/blog/2023_08_18_forward_pathtracing_in_comp_shader" || path_name === "/blog/2023_08_18_forward_pathtracing_in_comp_shader.html" }
 <Blog><L2023_08_18_forward_pathtracing_in_comp_shader slot="blog_post" /></Blog>
{:else if path_name === "/blog/2023_08_17_flame_fractals_in_comp_shader" || path_name === "/blog/2023_08_17_flame_fractals_in_comp_shader.html" }
 <Blog><L2023_08_17_flame_fractals_in_comp_shader slot="blog_post" /></Blog>
{/if}
<!-- WEBPACK_INSERT_BLOG_POSTS -->
	{:else}
		<Splash/>
	{/if}
</div>


<script lang="ts">
	window.LFS_PREPEND = window.is_dev ? '' : 'https://media.githubusercontent.com/media/wrightwriter/wrightwriter.github.io/master/'

	import '../../public/style.scss'
	import "./utils/wave-audio-path-player.es"

// WEBPACK_IMPORT_BLOG_POSTS
import L2023_08_18_forward_pathtracing_in_comp_shader from './blog/2023_08_18_forward_pathtracing_in_comp_shader.svelte'
import L2023_08_17_flame_fractals_in_comp_shader from './blog/2023_08_17_flame_fractals_in_comp_shader.svelte'

// WEBPACK_IMPORT_BLOG_POSTS
	
	import FavDemos from './favdemosPage.svelte'
	import Music from './musicPage.svelte'
	import ExeGfx from './favexegfxPage.svelte'
	import Art from './artPage.svelte'
	import Projects from './projectsPage.svelte'
	import About from './aboutPage.svelte'
	import Splash from './Splash.svelte'
	import Blog from './blogPage.svelte'
	console.log(RESOURCES);

  import lozad from 'lozad';

  let observer = lozad();
  observer.observe();
	
	window.addEventListener('DOMContentLoaded', function() {
		observer = lozad()
		observer.observe()
	})
	
	
	// $: head_title = "wrighter" + location.pathname + location.hash
	$: head_title = ""
	export let url = "/";
	// import Dexie, { type DBCoreRangeType } from "dexie"
	$: path_name = location.pathname 
</script>

<style lang="scss">
	:global(brb){
		display: block;
		// height: 2.4rem;
		height: 2.0rem;
		// margin: none;
	}
	:global(wbr){
		display: block;
		height: 1.5rem !important;
		// margin: none;
		// margin: none;
	}
	:global(*) {
		font-family: 'Petrona';
		font-weight: 400;
		color: var(--accent-dark);
		&::selection {
			background: var(--accent-dark);
			color: var(--accent-light);
		}
		-webkit-tap-highlight-color: transparent;
		--main-max-width: 40rem;
		--text-font-weight: 500;
		--link-font-weight: 800;
		// --accent-light: black;
		// --accent-dark: white;
		// #logo,.menu{
	}
	:global(a){
		text-decoration: underline;
		text-decoration-style: dashed;
		text-underline-offset: 0.2rem;
		&:hover{
			color: var(--accent-light) !important;
			background: var(--accent-dark);
			text-decoration: none;
			*{
			}
		}
	}
	:global(a:hover *){
		color: var(--accent-light) !important;
		text-decoration: none;
	}
	:global(.menu-item){
		text-decoration: none;
		font-weight: 900;
	}
	:global(#logo){
		text-decoration: none;
		// color: red;
		// background: black;
	}
	:global(::-webkit-scrollbar) {
		background: var(--accent-dark);
	}
	:global(::-webkit-scrollbar-track) {
		background: var(--accent-light);
	}
	:global(::-webkit-scrollbar-thumb) {
		background: var(--accent-dark);
	}
	:global(*){
		scrollbar-color: var(--accent-dark) var(--accent-light);
		scrollbar-width: auto;
		// scrollbar-track-color: ;
		// scrollbar-width: 2rem;
	}
	:global(main>*){
		margin-left: 1rem;
	}
	:global(main){
		width: 100%;
		max-width: 40rem;
		height: 100%;
		display: flex;
		flex-direction: column;
		max-width: var(--main-max-width);
		margin-right: auto;
		margin-left: auto;
	}
	canvas {
		position: absolute;
		top: 0;
    left: 0;
		z-index: -5;
		// display: none;
		width: 100%;
		height: 100%;
		// display: block;
		margin: auto;
		padding: 0;
	}
	
	
	main :global(.player *){
	}
	:global(.player::part(player) ){
	}

	:global(.player::part(play) ){
		stroke: black !important;
	}
	:global(.player::part(button) ){
		fill: black !important;
	}

  :global(.player::part(duration)){
		display: none;
		font-family: 'Petrona' !important;
	}
	:global(.player::part(currenttime) ){
		display: none;
		font-weight: 400;
	}
	:global(.player::part(input) ){
		overflow: hidden;
	}

	:global(.player::part(slider) ){
	}
	:global(.player::part(path1) ){
	}

	:global(.player::part(path2) ){
		stroke: black !important;
	}
</style>
