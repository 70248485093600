
<main>
	<Bar />
		<div class="name">
			Splunge
		</div>
		<div class="description">
			<div>
				<a href="https://github.com/wrightwriter/splunge" target="_blank">Splunge</a> is a painting software inspired by the lovely app <a href="https://www.heavypaint.com"  target="_blank">HeavyPaint</a>. 
			</div>
			<wbr />
			<div>
				The UI has common actions like brush size and HSV adjustments as gestures, triggered by the pen button. 
				Features include user gallery and infinite undo.  
				I wrote it using <a href="https://svelte.dev/"  target="_blank" >Svelte</a> and <a href="https://www.youtube.com/watch?v=3lOptjAeA2w"  target="_blank" >WebGL</a>, targeting my budget Galaxy Tab S6 Lite tablet. 
				This created the need of a lot of micro optimizations and profiling, but ended up running nicely! 
			</div>
			<wbr />
			<div>
				Here are some paintings I made with it:
			</div>
			<wbr />
		</div>
		<div class="gallery">
			<img src={window.LFS_PREPEND + "./images/paintings/adsg.png"} height="345.5rem"/>
			<img src={window.LFS_PREPEND + "./images/paintings/1689957895824_2 (1).png"} height="345.5rem" />
			<img src={window.LFS_PREPEND + "./images/paintings/1690037672960_1 (1).png"} height="290.5rem"  />
			<img src={window.LFS_PREPEND + "./images/paintings/1690102042507_1[1].png"} height="290.5rem"  />
		</div>
		<Footer />
</main>



<script lang="ts">
	import {onMount} from 'svelte'
	import {onDestroy} from 'svelte'

	import Bar from "./utils/NavBar.svelte"
	import Footer from './utils/Footer.svelte'

	
	onDestroy(() => {})
</script>

<style lang="scss">
	.name{
		font-weight: bolder;
		font-size: 2rem;
		margin-bottom: 1.0rem;
		margin-top: 0.9rem;
	}
	.gallery{
		display: flex;
		flex-wrap: wrap;
	}
	wbr{
		// display: block;
		// height: 0.02rem !important;
		// margin: none;
	}
	.description{
		display: flex;
		flex-direction: column;
		font-weight: var(--text-font-weight);
		a {
			font-weight: var(--link-font-weight);
		}
		*{
			font-weight: var(--text-font-weight);
			font-size: 1.0rem;
		}
	}
	main {
		width: 100%;
		max-width: 40rem;
		min-height: 100%;
		height: fit-content;
		// overflow: scroll;
		display: flex;
		flex-direction: column;
		max-width: var(--main-max-width);
		canvas {
			display: none;
			width: 100%;
			height: 100%;
			// display: block;
			margin: auto;
			padding: 0;
		}
	}
</style>

