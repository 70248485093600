
<main>
	<Bar>
		{#each Object.keys(audio_folders).reverse() as audio_folder, i}
			<a href={`#${audio_folder}`} on:click={()=>{    
				scroll_to_view(`#${audio_folder}`)}}
				class="menu-item"
			>
				{audio_folder}
			</a>
		{/each}
	</Bar>
	{#each Object.keys(audio_folders).reverse() as audio_folder_key, k}
		<!-- svelte-ignore a11y-no-static-element-interactions -->
		<div class="title-container" on:click={()=>{audio_folders_toggled[k] = !audio_folders_toggled[k]}}>
			<div class="title" id={audio_folder_key}>
				{audio_folder_key}
			</div>
			<svg 
				xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"
				style={audio_folders_toggled[k] ? "" : "transform: scaleY(-1);"}
			>
				<path d="M480-360 280-559h400L480-360Z" />
			</svg>
		</div>
		<div class="tracks" style={audio_folders_toggled[k] ? `` : `display: none;`}>
			<!-- {#each Object.keys(audio_folders[audio_folder_key]) as audio_key, i} -->
			{#each audio_folders[audio_folder_key]["sorted"] as audio_file, i}
					<div class="track">
						<wave-audio-path-player 
							src={`${window.LFS_PREPEND}${audio_file.src}`}
							class="player"
							wave-width="200" 
							wave-height="80" 
							color="black" 
							wave-slider="black"
							></wave-audio-path-player>
							<!-- wave-options={`{"animation":true,"samples":50}`} -->
						<div class="track-bottom-bar">
							<div class="track-name">
							{audio_file.name}
							</div>
							<div class="track-date">
							{audio_file.creation_date}
							</div>
						</div>
					</div>
			{/each}
		</div>
	{/each}
	<Footer />
</main>



<script lang="ts">
	import {onMount} from 'svelte'
	import {onDestroy} from 'svelte'

	import Bar from "./utils/NavBar.svelte"

	
	import Footer from './utils/Footer.svelte'
	// import 'wave-audio-path-player'

	function scroll_to_view(selector: string) {
		audio_folders_toggled[Object.keys(audio_folders).indexOf(selector.replace("#",""))] = true
		// image_cat_toggled[image_cat_names.indexOf(selector.replace("#",""))] = false
		// @ts-ignore
		document.querySelector(selector).scrollIntoView({
			behavior: 'smooth'
		})
	}

	
	class Demo{
		name: string
		group: string
		constructor(name: string, group: string){
			this.name = name
			this.group = group 

		}
	}
	const audio_folders: {[key: string]: string} = RESOURCES["public"]["audio"]
	const audio_folders_toggled: Array<boolean> = []
	
	
	export let url = "/favdemos";

	onMount(async () => {
		let i = 0
		for(let folder of Object.keys(audio_folders)){
			audio_folders_toggled.push(false)
			i++
		}
	})

	onDestroy(() => {})
</script>

<style lang="scss">
	

	.title-container{
		margin-left: 2rem;
		width: fit-content;
		cursor: pointer;
		display: flex;
		align-items: center;
		svg{
			transition: transform 0.1s ease;
		}
		&:active{
			filter: invert(1);
			background: var(--accent-light);
		}
		.title{
			font-weight: bolder;
			font-size: 2rem;
			margin-bottom: 2rem;
			margin-top: 2rem;
		}
	}
	
	.tracks{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		.track{
			display: flex;
			width: fit-content;
			flex-direction: column;
			// justify-content: end;
			align-items: end;
			margin-bottom: 0.5rem;
			.title{
				// margin-left: auto;
				// margin-rigt: auto;
			}
			.track-bottom-bar{
				height: 2rem;
				.track-name{
					font-weight: 600;
					margin-left: 1rem;
					width: 10rem;
				}
				.track-date{
					margin-right: 0.4rem;
				}
				justify-content: space-between;
				width: 100%;
				display: flex;
			}
		}
	}
	main :global(*) {
		color: var(--accent-dark);
		font-family: 'Petrona';
		font-weight: 400;
		&::selection {
			background: var(--accent-dark);
			color: var(--accent-light);
		}
	}
	main {
		width: 100%;
		max-width: 40rem;
		min-height: 100%;
		height: fit-content;
		// overflow: scroll;
		display: flex;
		flex-direction: column;
		max-width: var(--main-max-width);
	}
</style>

