
<main>
	<Bar />
	<slot name="blog_post"/>
	{#if !$$slots.blog_post}
		{#each Object.keys(window.BLOG_POSTS) as blog_post}
			<div class="blog-posts-container">
				<div class="blog-post">
					<!-- <a href={"blog/" + blog_post + window.is_dev ? "html" : ""} class="blog-post-name"> -->
					<a href={`blog/${blog_post}${window.is_dev ? ".html" : ""}`} class="blog-post-name">
						{window.BLOG_POSTS[blog_post].title}
					</a>
					<div class="blog-post-date">
						{window.BLOG_POSTS[blog_post].date}
					</div>
				</div>
			</div>
			<wbr />
			
		{/each}
	<!-- amogus -->

	{/if}
	<Footer />
</main>



<script lang="ts">
	import {onMount} from 'svelte'
	import {onDestroy} from 'svelte'
	// function requireAll(r) { r.keys().forEach(r); }
	// const a = requireAll(require.context('./', true, /\.svelte$/));
	// console.log(a)
	// console.log("adgasdgasdgasdgasdgsdg")

	import Bar from "./utils/NavBar.svelte"
	import Footer from './utils/Footer.svelte'
	
	
	onDestroy(() => {})
</script>

<style lang="scss">
	:global(article){
		padding-left: 1rem;
    padding-right: 1rem;
		display: flex;
    flex-direction: column;
		// width: 100%;
		width: -webkit-fill-available;
		width: -moz-available;
		width: fill-available;
		max-width: 40rem;
		min-height: 100%;
		height: fit-content;
		// overflow: scroll;
		display: flex;
		flex-direction: column;
		max-width: var(--main-max-width);
		margin-left: auto;
    margin-right: auto;
	}
	:global(video, img) {
		max-width: 30rem;
		width: inherit;
		margin-right: auto;
		margin-left: auto !important;
	}
  :global(.video-container){
		height: fit-content;
    margin: 0px auto;
    max-width: 30rem;
    width: 30rem;
		width: inherit;
		display: table;
    :global(div){
      position: relative;
      padding-bottom: 75%;
      height: 0px;
      :global(iframe){  
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
      }
    }
  }

	:global(code, pre){
		font-size: 0.8rem !important;
	}
  :global( code, code *, pre ) {
    // text-wrap: wrap !important;
    // word-wrap: normal;
    // word-break: normal;
		overflow-wrap: break-word;
		word-wrap: break-word;
		// word-break:;
    white-space: normal;
    overflow: auto;
  }
  :global(*::marker){
    list-style-type: '👉';
    // content: '✝ ';
    // content: 'Ͱ ';
    // content: 'Ѫ ';
    content: '܀ ';
    font-size: 1.5rem;
    display: list-item;
    padding-inline-start: 1ch;
  }
  :global(p){
    margin-bottom: 0px;
  }
  :global(img){
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;
  }

  :global(.date){
    margin-left: auto;
  }
  :global(.video-container){
    // width: 20rem;
    // height: 20rem;
    // max-width: 420px;
    margin: 0px auto;
    max-width: 30rem;
    width: 30rem;
    div{
      // max-width: 30rem;
      // min-width: 0px;
      position: relative;
      padding-bottom: 75%;
      height: 0px;
      
      iframe{  
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
      }
    }
  }
	.blog-posts-container{
		margin-top: 1rem;
		.blog-post{
			width: 100%;
			display: flex;
			flex-direction: column;
			.blog-post-name{
				font-size: 2rem;
			}
			.blog-post-date{
				font-size: 1.3rem;
				margin-left: auto;
			}
		}
	}
name{
		font-weight: bolder;
		font-size: 2rem;
		margin-bottom: 1.0rem;
		margin-top: 0.9rem;
	}
	.gallery{
		display: flex;
		flex-wrap: wrap;
	}
	wbr{
		display: block;
		height: 0.02rem;
		margin: none;
		// margin: none;
	}
	.description{
		display: flex;
		flex-direction: column;
		font-weight: var(--text-font-weight);
		a {
			font-weight: var(--link-font-weight);
		}
		*{
			font-weight: var(--text-font-weight);
			font-size: 1.0rem;
		}
	}
	main {
		width: 100%;
		max-width: 40rem;
		min-height: 100%;
		height: fit-content;
		// overflow: scroll;
		display: flex;
		flex-direction: column;
		max-width: var(--main-max-width);
		canvas {
			display: none;
			width: 100%;
			height: 100%;
			// display: block;
			margin: auto;
			padding: 0;
		}
	}
</style>

