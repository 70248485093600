<!-- OpenGraph description: Artworks by wrighter -->
<!-- OpenGraph type: article -->

<main>
	<Bar>
	</Bar>
	<ImageViewer src="" alt="..." bind:this={image_viewer} />
	{#each image_cats as image_cat, i}
		<!-- svelte-ignore a11y-no-static-element-interactions -->
		<div class="title-container" on:click={()=>{image_cat_toggled[i] = !image_cat_toggled[i]}}>
			<div class="title" id={image_cat_names[i]}>
				{image_cat_names[i]}
			</div>
			<svg 
				xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"
				style={image_cat_toggled[i] ? "" : "transform: scaleY(-1);"}
			>
				<path d="M480-360 280-559h400L480-360Z" />
			</svg>
		</div>
		<Gallery 
			images={image_cat} 
			hidden={image_cat_toggled[i]}
			open_image_callback={
				// @ts-ignore
				(src)=>{image_viewer.open_image(src)}
			}
			/>
	{/each}
	<!-- {#each Object.keys(image_folders) as image_folder, i}
		{#if typeof image_folders[image_folder] === "object"}
			<div class="year">
				{image_folder}
			</div> -->
			<!-- <Masonry colWidth="10.5rem;">
				{#each Object.keys(image_folders[image_folder]) as image, k}
					<img src={image_folders[image_folder][image]} />
				{/each}
			</Masonry> -->
		<!-- {/if}
	{/each} -->
	<Footer />
</main>



<script lang="ts">
	import {onMount} from 'svelte'
	import {onDestroy} from 'svelte'

	import Bar from "./utils/NavBar.svelte"

	import Gallery from './utils/Gallery.svelte';
	import Footer from './utils/Footer.svelte'

	import ImageViewer from "./utils/svelte-zoom/ImageViewer.svelte"
	
	let image_viewer: ImageViewer
	
  
	
	function scroll_to_view(selector: string) {
		image_cat_toggled[image_cat_names.indexOf(selector.replace("#",""))] = false
		// @ts-ignore
		document.querySelector(selector).scrollIntoView({
			behavior: 'smooth'
		})
	}
	
	// import 'wave-audio-path-player'

	
	const image_folders: {
		[key: string]: {
			[key: string]: string | number
		}
	} = RESOURCES["public"]["images"]
	
	const image_cats: Array<Array<any>> = []
	const image_cat_names: Array<string> = []
	const image_cat_toggled: Array<boolean> = []
	
	for (let image_folder_name of Object.keys(image_folders)){
		if(typeof image_folders[image_folder_name] === "object" && !image_folders[image_folder_name]["src"]){
			let folder: Array<any> = []
			let image_keys = Object.keys(image_folders[image_folder_name])
			image_cats.push(folder)
			if(image_keys.length > 0) { 
				image_cat_names.push(image_folder_name) 
				image_cat_toggled.push(true) 
			}
			for(let image_key of image_keys){
				let image = image_folders[image_folder_name][image_key]

				const is_video = image["is_video"]
				const has_thumb = !!image["src_thumb"]
				// window.LFS_PREPEND + image['src_thumb'] ? image['src_thumb'] : image.src 
				if(image["src"]){
					folder.push({
						src: window.LFS_PREPEND + image["src"],
						src_thumb: has_thumb ? window.LFS_PREPEND + image["src_thumb"] : false,
						is_video: is_video,
						width: image["width"],
						height: image["height"],
						// width: 400,
						// height: 400,
					})
				}
			}
		}
	}
	
	console.log("CAAAAAAAAAAAAAAAAAAAAATS")
	console.log(image_cats)
	
	
	export let url = "/favdemos";

	onMount(async () => {
		const hash = window.location.hash;
		setTimeout(()=>{
			try{
				scroll_to_view(hash)
			} catch(e){

			}
		}, 200)
	})

	onDestroy(() => {})
</script>

<style lang="scss">
	main :global(*) {
		color: var(--accent-dark);
		font-family: 'Petrona';
		font-weight: 400;
		&::selection {
			background: var(--accent-dark);
			color: var(--accent-light);
		}
	}
	.title-container{
		margin-left: 1rem;
		width: fit-content;
		cursor: pointer;
		display: flex;
		align-items: center;
		svg{
			transition: transform 0.1s ease;
		}
		&:active{
			filter: invert(1);
			background: var(--accent-light);
		}
		.title{
			font-weight: bolder;
			font-size: 2rem;
			margin-bottom: 2rem;
			margin-top: 2rem;
		}
	}
	.demo{
		width: fit-content;
		display: flex;
		// align-items: center;
		// align-items: end;
		// text-decoration-style: dashed !important;
		// text-decoration-thickness: 0.2rem !important;
		// text-decoration: underline;
		// outline: bo;

		*{
			font-size: 1.0rem;
		}
		.name{
			font-weight: bold;
		}
	}
	main {
		width: 100%;
		max-width: 40rem;
		min-height: 100%;
		height: fit-content;
		// overflow: scroll;
		display: flex;
		flex-direction: column;
		max-width: var(--main-max-width);
		canvas {
			display: none;
			width: 100%;
			height: 100%;
			// display: block;
			margin: auto;
			padding: 0;
		}
	}
	.hidden{
		display: none;
	}
</style>

