<svelte:options accessors />

<div class="container" bind:this={container_element} class:hidden={!opened}>
	<!-- svelte-ignore a11y-no-static-element-interactions -->
	<div
		class="close-button"
		on:click={() => {
			opened = false
		}}>
		{@html ForbidIcon}
	</div>
  {#if is_video}
	<video
    loop
    autoplay
		class="c-svelteZoom"
		class:c-svelteZoom--contain={contain}
		class:c-svelteZoom--no-contain={!contain}
		class:c-svelteZoom--transition={smooth}
		class:c-svelteZoom--visible={contain}
		class:c-svelteZoom--hidden={contain === null}
		class:c-svelteZoom--willChange={willChange}
    on:click={()=>{
      const mouse_vec_x = mouse[0] - start_mouse[0]
      const mouse_vec_y = mouse[1] - start_mouse[1]
      const mouse_vec_len = Math.sqrt(mouse_vec_x*mouse_vec_x + mouse_vec_y*mouse_vec_y)
      const mouse_vec_len_relative_to_screen = mouse_vec_len / Math.min(container_element.clientWidth, container_element.clientHeight)
      if(mouse_vec_len_relative_to_screen < 0.02){
        // @ts-ignore
        if(img.paused){
          // @ts-ignore
          img.play()
        } else {
          // @ts-ignore
          img.pause()
        }
      }
    }}
		bind:this={img}
		on:mousedown={mousedown}
		on:touchstart={touchstart}
		on:load={onLoad}
		{...$$props}
		src={src} />
  {:else}
	<img
		alt={alt}
		class="c-svelteZoom"
		class:c-svelteZoom--contain={contain}
		class:c-svelteZoom--no-contain={!contain}
		class:c-svelteZoom--transition={smooth}
		class:c-svelteZoom--visible={contain}
		class:c-svelteZoom--hidden={contain === null}
		class:c-svelteZoom--willChange={willChange}
		bind:this={img}
		on:mousedown={mousedown}
		on:touchstart={touchstart}
		on:load={onLoad}
		{...$$props}
		src={src} />
  {/if}
</div>

<script lang="ts">
	export let alt
	import Matrix from './matrix'
	import MultiTouchVelocity from './velocity'

	import ForbidIcon from '/../public/forbid.svg'

	import {calculateAspectRatioFit, getDistance} from './other'

	import {onMount} from 'svelte'

	let smooth = true
	let touchScreen = false
	let container_element: HTMLDivElement
	let opened: boolean = false
	let src: string = './dummy.png'
  // let is_video: boolean = false
  $: is_video = src.endsWith("mp4") || src.endsWith("webm")
  
  window.addEventListener("keydown", (e)=>{
    if(e.key === 'Escape'){
      opened = false
    }
  })

	let prev_mouse = [0, 0]
	let start_mouse = [0, 0]
	let mouse = [0, 0]
	let offs = [0, 0]

	let xY = {
		initX: 0,
		initY: 0,
		newX: 0,
		newY: 0,
	}

	let ratio
  let img: HTMLImageElement | HTMLVideoElement

	let matrix
	let contain = null
	let willChange = true

	let velocity = new MultiTouchVelocity()

	let lastTap = {
		time: 0,
		x: 0,
		y: 0,
	}

	let scale = {
		scaling: false,
		x1: 0,
		x2: 0,
		y1: 0,
		y2: 0,
		lastHypo: 0,
		originX: 0,
		originY: 0,
		value: 1.0,
		max: 1,
	}

	export const open_image = (_src: string) => {
		opened = true
		src = _src
		offs[0] = 0
		offs[1] = 1
		scale.value = 1

		let zoom = Math.pow(2, Math.log2(scale.value))
		img.style.transform = `translate(${offs[0]}px, ${offs[1]}px) scale(${zoom})`
		img.style['transform-origin'] = `${0}px ${0}px`
    
    img.addEventListener('load', () => {
      const w = img.width
      const h = img.height
      // const canv
    })
	}

	function fireDown(x, y) {
		xY.initX = x
		xY.initY = y

		matrix.x = matrix.vtm.e
		matrix.y = matrix.vtm.f

		willChange = true
	}

	function fireMove(x, y) {
		if (scale.scaling) return

		let delta_mouse = [x - prev_mouse[0], y - prev_mouse[1]]

		offs[0] += delta_mouse[0]
		offs[1] += delta_mouse[1]

		// let img_aspect = img.width / img.height
		// let viewport_aspect = container_element.clientWidth/container_element.clientHeight

		let zoom = Math.pow(2, Math.log2(scale.value))

		img.style.transform = `translate(${offs[0]}px, ${offs[1]}px) scale(${zoom})`
		img.style['transform-origin'] = `${0}px ${0}px`
	}

	function fireUp() {
		matrix.x -= xY.newX
		matrix.y -= xY.newY

		scale.scaling = false
		scale.lastHypo = 0
		smooth = true
		willChange = false
	}

	function fireScale(touchA, touchB) {
		const xTouch = [Math.min(touchA.pageX, touchB.pageX), Math.max(touchA.pageX, touchB.pageX)]

		const yTouch = [Math.min(touchA.pageY, touchB.pageY), Math.max(touchA.pageY, touchB.pageY)]

		const W = xTouch[1] - xTouch[0]
		const centerX = W / 2 + xTouch[0]

		const H = yTouch[1] - yTouch[0]
		const centerY = H / 2 + yTouch[0]

		scale.originX = centerX
		scale.originY = centerY
		scale.lastHypo = Math.trunc(getDistance(touchA, touchB))
		smooth = false
	}

	function fireTapScale(x, y) {
		let scaleVtm = matrix.vtm.a
		let scale_value = scaleVtm > 1 ? scaleVtm - 1 : scale.max / 2.5
		let scale_factor = scaleVtm > 1 ? -1 : 1

		const xFactor = 1 + scale_value * scale_factor
		const yFactor = (xFactor * window.innerHeight) / window.innerWidth

		let in_x = (window.innerWidth - ratio.width * Math.max(xFactor * scaleVtm, 1)) / 2
		let in_y = (window.innerHeight - ratio.height * Math.max(xFactor * scaleVtm, 1)) / 2

		const origin = {
			x: in_x > 0 ? window.innerWidth / 2 : x,
			y: in_y > 0 ? window.innerHeight / 2 : y,
		}

		const mat = matrix.scale(xFactor, yFactor, origin, in_x, in_y, ratio, scale.max, scale.value * xFactor, scale_factor)

		scale.value = mat.d
		img.style.transform = `translate(${mat.e}px, ${mat.f}px) scale(${mat.d})`
	}

	function fireScaleMove(touchA, touchB, e) {
		const hypo = getDistance(touchA, touchB)

		let f = hypo / scale.lastHypo

		f = f >= 1 ? 1 : -1

		const ff = velocity.getVelocity(touchA, touchB) || 1

		const xFactor = 1 + 0.1 * ff * f

		const yFactor = (xFactor * window.innerHeight) / window.innerWidth

		let in_x = (window.innerWidth - ratio.width * matrix.vtm.a) / 2
		let in_y = (window.innerHeight - ratio.height * matrix.vtm.a) / 2

		const origin = {
			x: in_x > 0 ? window.innerWidth / 2 : scale.originX,
			y: in_y > 0 ? window.innerHeight / 2 : scale.originY,
		}

		const mat = matrix.scale(xFactor, yFactor, origin, in_x, in_y, ratio, scale.max, scale.value * xFactor, f)

		img.style.transform = `translate(${mat.e}px, ${mat.f}px) scale(${mat.d})`

		scale.value = mat.d

		scale.lastHypo = hypo
		scale.scaling = true
	}

	function fireManualZoom(dir) {
		const xFactor = 1 + 0.2 * dir
		const yFactor = (xFactor * window.innerHeight) / window.innerWidth

		let in_x = (window.innerWidth - ratio.width * matrix.vtm.a) / 2
		let in_y = (window.innerHeight - ratio.height * matrix.vtm.a) / 2

		const origin = {
			x: window.innerWidth / 2,
			y: window.innerHeight / 2,
		}

		const mat = matrix.scale(xFactor, yFactor, origin, in_x, in_y, ratio, scale.max, scale.value * xFactor, dir)
		img.style.transform = `translate(${mat.e}px,${mat.f}px) scale(${mat.d})`
		scale.value = mat.d
	}

	export const zoomIn = () => fireManualZoom(1)

	export const zoomOut = () => fireManualZoom(-1)

	function onResize() {
		onLoad()
		fireDown(0, 0)
		fireMove(0, 0)
		fireUp()
	}

	function onWheel(e: WheelEvent) {
		e.preventDefault()
		const dir = e.deltaY < 0 ? 1 : -1

		let prev = Math.pow(2, Math.log2(scale.value))

		if (dir > 0) {
			scale.value *= 1.2
		} else {
			scale.value /= 1.2
		}
		let zoom = Math.pow(2, Math.log2(scale.value))
		const ratio = 1 - zoom / prev

		let img_aspect = img.width / img.height
		let viewport_aspect = container_element.clientWidth / container_element.clientHeight
		let mouse_pos = [e.clientX, e.clientY]

		offs[0] += (e.clientX - offs[0]) * ratio
		offs[1] += (e.clientY - offs[1]) * ratio

		img.style.transform = `translate(${offs[0]}px, ${offs[1]}px) scale(${zoom})`
		img.style['transform-origin'] = `${0}px ${0}px`
	}

	function onLoad() {
		// const {naturalWidth, naturalHeight} = img
    let naturalHeight: number
    let naturalWidth: number
    if(is_video){
      naturalWidth = (img as HTMLVideoElement).videoWidth;
      naturalHeight = (img as HTMLVideoElement).videoHeight;
      // img.addEventListener('load', () => {
      (img as HTMLVideoElement).play()
      // })
    } else {
      naturalWidth = (img as HTMLImageElement).naturalWidth
      naturalHeight = (img as HTMLImageElement).naturalHeight
    }
    // let vid = img as HTMLVideoElement

		contain = naturalWidth > window.innerWidth || naturalHeight > window.innerHeight

		scale.max =
			naturalWidth > naturalHeight
				? Math.max(naturalWidth / window.innerWidth, 1)
				: Math.max(naturalHeight / window.innerHeight, 1)
		ratio = calculateAspectRatioFit(naturalWidth, naturalHeight, window.innerWidth, window.innerHeight)
	}

	onMount(() => {
		matrix = new Matrix()
		container_element.addEventListener('wheel', onWheel, {passive: false})
		container_element.addEventListener('resize', onResize)
		return () => {
			container_element.removeEventListener('wheel', onWheel)
			container_element.removeEventListener('resize', onResize)
		}
	})

	function onTouchStart(e) {
		touchScreen = true
		willChange = true
		const isMultiTouch = e.touches.length === 2
		const [touchA, touchB] = e.touches

		scale.scaling = isMultiTouch

		smooth = false
		if (isMultiTouch) {
			fireScale(touchA, touchB)

			velocity.down(touchA, touchB)
		} else {
			const {pageX, pageY} = touchA
			var now = new Date().getTime()
			if (now - lastTap.time < 250 && Math.hypot(lastTap.x - pageX, lastTap.y - pageY) <= 20) {
				smooth = true
				fireTapScale(pageX, pageY)
			} else {
				fireDown(pageX, pageY)
			}

			lastTap = {
				time: now,
				x: pageX,
				y: pageY,
			}
		}

		window.removeEventListener('touchmove', onTouchMove)
		window.removeEventListener('touchend', onTouchEnd)
		window.addEventListener('touchmove', onTouchMove)
		window.addEventListener('touchend', onTouchEnd)
	}

	function onTouchMove(e) {
		if (scale.scaling) {
			const [touchA, touchB] = e.touches
			fireScaleMove(touchA, touchB)
		} else {
			fireMove(e.touches[0].pageX, e.touches[0].pageY)
		}
	}

	function onTouchEnd(e) {
		fireUp()
		window.removeEventListener('touchmove', onTouchMove)
		window.removeEventListener('touchend', onTouchEnd)
		window.removeEventListener('touchcancel', onTouchEnd)
	}

	function onMouseDown({clientX, clientY}) {
    start_mouse = [clientX, clientY]
		prev_mouse = [clientX, clientY]
    mouse = [clientX, clientY]
		if (touchScreen) return
		fireDown(clientX, clientY)

		smooth = false

		window.addEventListener('mousemove', onMouseMove)
		window.addEventListener('mouseup', onMouseUp)
	}

	function onMouseMove({clientX, clientY}) {
		fireMove(clientX, clientY)
		prev_mouse = [clientX, clientY]
    mouse[0] = clientX
    mouse[1] = clientY
	}

	function onMouseUp() {
		window.removeEventListener('mousemove', onMouseMove)
		fireUp()
	}

	const mousedown = onMouseDown
	const touchstart = onTouchStart
</script>

<style lang="scss">
	.container {
		width: 100%;
		height: 100%;
		position: fixed;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100vw;
		height: 100vh;
    margin-left: 0;
		left: 0;
		top: 0;
		z-index: 100;
		background: var(--accent-light);
	}
	.c-svelteZoom {
		width: 100%;
		height: 100%;
		position: absolute;
		transform-origin: 0 0;
		backface-visibility: hidden;
		-webkit-backface-visibility: hidden;
		-moz-backface-visibility: hidden;
		-o-backface-visibility: hidden;
		-ms-backface-visibility: hidden;
		-webkit-user-drag: none;
		-moz-user-drag: none;
		-o-user-drag: none;
		user-drag: none;
		touch-action: none;
		// pointer-events: none;
	}

	.c-svelteZoom--contain {
		object-fit: contain;
	}

	.c-svelteZoom--no-contain {
		object-fit: contain;
	}

	.c-svelteZoom--transition {
		transition: transform 0.2s;
	}

	.c-svelteZoom--visible {
		visibility: visible;
	}

	.c-svelteZoom--hidden {
		visibility: hidden;
	}

	.c-svelteZoom--willChange {
		will-change: transform;
	}

	.close-button {
		z-index: 1;
		background: var(--accent-light);
		position: absolute;
		right: 1rem;
		cursor: pointer;
		&:hover {
			filter: invert(1);
		}
		:global(svg) {
			fill: var(--accent-dark);
			stroke: var(--accent-dark);
			aspect-ratio: 1/1;
			width: 6rem;
		}
	}
	.hidden {
		display: none;
	}
</style>
