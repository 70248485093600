
<main>
	<Bar />
	<div>
	</div>
	{#each Object.keys(list).reverse() as year, i}
		<div class="year">
			{year}
		</div>
		{#each list[year] as demo, i}
 
			<a href={demo.link} target=”_blank” class="demo">
				<div class="name">
					{demo.name} - {demo.group}
				</div>
				<img class="lozad"  data-src={demo.img_url} height="345.5rem"/>
			</a>
			<!-- <a href={demo.link} target=”_blank” on:pointerenter={()=>showImage(demo)} on:pointerleave={()=>hideImage()} class="demo"> -->
				<!-- <div class="name">
					{demo.name} &nbsp; — &nbsp; {demo.group}
				</div> -->
				<!-- <div class="name">
					{demo.name}
				</div>
				<div style="font-weight: 500;">
					&nbsp; — &nbsp;
				</div>
				<div class="group">
					{demo.group}
				</div> -->
			<!-- </a> -->
		{/each}
	{/each}
	<img 
		class="hover-image" 
		bind:this={hover_image_element} 
		style={`
			background:var(--accent-dark);
			display:none;
			position: fixed;
			pointer-events: none;
			position:fixed;
			min-width: 1rem;
			min-height: 1rem;
		`}
	/>
	<Footer />
</main>



<script lang="ts">
	import {onMount} from 'svelte'
	import {onDestroy} from 'svelte'

	import Bar from "./utils/NavBar.svelte"

	import Footer from './utils/Footer.svelte'
	
	let hover_image_element: HTMLImageElement
	
	function showImage(demo: ExeGfx){
		hover_image_element.style.display = "block"
		hover_image_element.src = demo.img_url
	}
	function hideImage(){
		hover_image_element.style.display = "none"
	}
	
	class ExeGfx{
		name: string
		group: string
		link: string
		img_url: string
		constructor(name: string, group: string, link: string, img_url: string = ""){
			this.name = name
			this.group = group 
			this.link = link
			this.img_url = img_url
		}
	}
	const list = {
		2024: [
			new ExeGfx("Tale Of Aeod"  ,"mocoo", 
			"https://demozoo.org/graphics/355407/", "https://media.demozoo.org/screens/o/5a/16/e1c0.348961.png"),
			new ExeGfx("Fragments Of Self"  ,"yx", 
			"https://demozoo.org/graphics/342289/", "https://media.demozoo.org/screens/o/82/79/6b53.342069.png"),
		],
		2023: [
			new ExeGfx("La Chasse Aux œuf (The Egg Hunt)"  ,"cce", 
			"https://demozoo.org/graphics/322463/", "https://media.demozoo.org/screens/o/ef/e5/f4ce.324546.png"),
			new ExeGfx("RiverScape"  ,"Anat", 
			"https://demozoo.org/graphics/322476/", "https://media.demozoo.org/screens/o/07/9e/d17a.324400.jpg"),
			new ExeGfx("Bon appetite"  ,"kinankomoti", 
			"https://demozoo.org/graphics/331893/", "https://media.demozoo.org/screens/o/62/89/4b15.334012.png"),
			new ExeGfx("Porcelain Army: All Fired Up"  ,"Sumaleth", 
			"https://demozoo.org/graphics/333957/", "https://media.demozoo.org/screens/o/99/bd/25c7.335226.jpg"),
			new ExeGfx("Mentally,"  ,"noby", 
			"https://demozoo.org/graphics/322472/", "https://media.demozoo.org/screens/o/7e/8a/d5e7.324548.png"),
			// new ExeGfx("seven days"  ,"61Q0", "https://www.pouet.net/prod.php?which=91421", "https://content.pouet.net/files/screenshots/00091/00091421.jpg"),
			// new ExeGfx("katsumaki"  ,"still", "https://www.pouet.net/prod.php?which=95242", "https://content.pouet.net/files/screenshots/00095/00095242.jpg"),
		],
 		2022: [
			new ExeGfx("Grazing"  ,"blackle", 
			"https://demozoo.org/graphics/307501/", "https://media.demozoo.org/screens/o/7f/9d/5252.307597.jpg"),
			new ExeGfx("Orders of Magnitude"  ,"P_Malin", 
			"https://demozoo.org/graphics/308530/", "https://media.demozoo.org/screens/o/6c/93/dc05.308560.png"),
			new ExeGfx("Dissecting the Scene Syndrome"  ,"Seven", 
			"https://demozoo.org/graphics/308531/", "https://media.demozoo.org/screens/o/5e/d0/78ce.314237.png"),
			new ExeGfx("Dreamtime Return"  ,"noby", 
			"https://demozoo.org/graphics/307507/", "https://media.demozoo.org/screens/o/8c/7f/11a8.307594.png"),
		],
		2021: [
			new ExeGfx(
				"Autumn Years"  ,"noby", 
				"https://demozoo.org/graphics/292423/", 
				"https://media.demozoo.org/screens/o/1b/b4/53dc.288794.png"),
			new ExeGfx(
				"You Are Here"  ,"tdhooper", 
				"https://demozoo.org/graphics/292426/", 
				"https://media.demozoo.org/screens/o/27/76/91e0.288844.png"),
			new ExeGfx(
				"Is This Your Card?"  ,"yx", 
				"https://demozoo.org/graphics/307508/", 
				"https://media.demozoo.org/screens/o/bb/30/a21c.307593.png"),
			new ExeGfx(
				"Submit"  ,"yx", 
				"https://www.pouet.net/prod.php?which=88577", 
				"https://media.demozoo.org/screens/o/fb/ae/79c2.288758.png"),
			new ExeGfx(
				"Two Triangles"  ,"yx", 
				"https://demozoo.org/graphics/301325/", 
				"https://media.demozoo.org/screens/o/cb/11/e801.299847.png"),
			new ExeGfx(
				"The Dude abides"  ,"abductee", 
				"https://www.pouet.net/prod.php?which=88614", 
				"https://media.demozoo.org/screens/o/b8/84/3ca0.288832.png"),
			new ExeGfx(
				"Bull"  ,"EvilRyu", 
				"https://demozoo.org/graphics/292424/", 
				"https://media.demozoo.org/screens/o/cd/51/b9f2.288833.png"),
			new ExeGfx(
				"Making Good Progress"  ,"fizzer", 
				"https://www.pouet.net/prod.php?which=88581", 
				"https://media.demozoo.org/screens/o/44/ab/f88a.288767.png"),
			new ExeGfx(
				"Watchtower"  ,"slerpy", 
				"https://demozoo.org/graphics/298069/", 
				"https://media.demozoo.org/screens/o/03/6d/3ee4.296665.png"),
			new ExeGfx(
				"Caffeinate"  ,"P_Malin", 
				"https://demozoo.org/graphics/302080/", 
				"https://media.demozoo.org/screens/o/b0/79/6691.300716.png"),
			new ExeGfx(
				"Creative Block"  ,"yx", 
				"https://demozoo.org/graphics/294426/", 
				"https://media.demozoo.org/screens/o/ec/28/14f8.291888.png"),
			new ExeGfx(
				"Kuwaharas Painting (桑原の絵画)"  ,"slerpy", 
				"https://demozoo.org/graphics/303082/", 
				"https://media.demozoo.org/screens/o/a3/e5/936b.301811.png"),
		],
		2020: [
			new ExeGfx(
				"The Engineer"  ,"bitnenfer", 
				"https://demozoo.org/graphics/280519/", 
				"https://media.demozoo.org/screens/o/14/b9/2a7e.273954.png"),
			new ExeGfx(
				"The Real Party is in Your Pocket"  ,"fizzer", 
				"https://demozoo.org/graphics/277164/", 
				"https://media.demozoo.org/screens/o/4b/2e/dba0.264365.png"),
			new ExeGfx(
				"Enamel Pin"  ,"blackle", 
				"https://demozoo.org/graphics/280518/", 
				"https://media.demozoo.org/screens/o/bb/6f/2244.273889.jpg"),
			new ExeGfx(
				"Phyllotaxes"  ,"tdhooper", 
				"https://demozoo.org/graphics/279419/", 
				"https://media.demozoo.org/screens/o/15/3b/22b4.272160.png"),
			new ExeGfx(
				"Hoody"  ,"iq", 
				"https://demozoo.org/graphics/277165/", 
				"https://media.demozoo.org/screens/o/ac/8d/2f24.264364.png"),
			new ExeGfx(
				"Waterfall"  ,"nusan", 
				"https://demozoo.org/graphics/277163/", 
				"https://media.demozoo.org/screens/o/2f/5c/4e0c.264363.png"),
			new ExeGfx(
				"Long Way From Home"  ,"yx", 
				"https://demozoo.org/graphics/273891/", 
				"https://media.demozoo.org/screens/o/15/5b/74b9.259014.png"),
			new ExeGfx(
				"Reconstruction"  ,"alia", 
				"https://demozoo.org/graphics/281254/", 
				"https://media.demozoo.org/screens/o/8a/86/b98d.275066.jpg"),
			new ExeGfx(
				"Ice core"  ,"alia", 
				"https://demozoo.org/graphics/278502/", 
				"https://media.demozoo.org/screens/o/76/e7/a695.267018.png"),
			new ExeGfx(
				"Kuu"  ,"yx", 
				"https://demozoo.org/graphics/275127/", 
				"https://media.demozoo.org/screens/o/6a/33/a588.260749.png"),
		],
		2019: [
			new ExeGfx(
				"Riftonia by Rail"  ,"Rift", 
				"https://demozoo.org/graphics/173387/", 
				"https://media.demozoo.org/screens/o/d7/d2/bbc6.184662.png"),
			new ExeGfx(
				"0DAY Cove"  ,"Anat", 
				"https://www.pouet.net/prod.php?which=81045", 
				"https://media.demozoo.org/screens/o/c2/1b/0f04.184983.jpg"),
			new ExeGfx(
				"Penrose Pathtraced"  ,"yx", 
				"https://demozoo.org/graphics/202726/", 
				"https://media.demozoo.org/screens/o/5e/b8/d55a.277194.png"),
			new ExeGfx(
				"Brutal Knowledge"  ,"yx", 
				"https://www.pouet.net/prod.php?which=83690", 
				"https://media.demozoo.org/screens/o/1d/8c/ed2b.239095.jpg"),
			new ExeGfx(
				"ESCHERWAVE"  ,"tdhooper", 
				"https://www.pouet.net/prod.php?which=81080", 
				"https://media.demozoo.org/screens/o/df/00/6c97.184834.png"),
			new ExeGfx(
				"International Shipping"  ,"blackle", 
				"https://www.pouet.net/prod.php?which=81049", 
				"https://media.demozoo.org/screens/o/92/67/4fcf.184836.jpg"),
			new ExeGfx(
				"A Fish Best Served Cold"  ,"fizzer", 
				"https://demozoo.org/graphics/202730/", 
				"https://media.demozoo.org/screens/o/95/85/c60c.185280.png"),
			new ExeGfx(
				"Veistos/Kulhot"  ,"noby", 
				"https://www.pouet.net/prod.php?which=81318", 
				"https://content.pouet.net/files/screenshots/00081/00081318.jpg"),
			new ExeGfx(
				"Devour"  ,"noby", 
				"https://demozoo.org/graphics/202725/", 
				"https://media.demozoo.org/screens/o/21/b8/2f2f.185298.png"),
		],
		2017: [
			new ExeGfx(
				"Sunset"  ,"Blackwine / Jok / Kiero", 
				"https://demozoo.org/graphics/178204/", 
				"https://media.demozoo.org/screens/o/c0/9c/3999.147241.png"),
			new ExeGfx(
				"Takochu Kiss"  ,"fizzer", 
				"https://demozoo.org/graphics/168486/", 
				"https://media.demozoo.org/screens/o/8d/81/8d43.126052.png"),
		],
		2013: [
			new ExeGfx(
				"Fruxis"  ,"iq", 
				"https://demozoo.org/graphics/43990/", 
				"https://media.demozoo.org/screens/o/aa/4e/3978.pl36134.jpg"),
		],
		2012: [
			new ExeGfx(
				"8 Ball"  ,"westlicht", 
				"https://www.pouet.net/prod.php?which=61791", 
				"https://media.demozoo.org/screens/o/66/3f/0b7a.35637.png"),
			new ExeGfx(
				"Milkdrop"  ,"Noobody", 
				"https://demozoo.org/graphics/87898/", 
				"https://media.demozoo.org/screens/o/1a/2c/2b08.37056.png"),
		],
		2009: [
			new ExeGfx(
				"Patter"  ,"doomdoom", 
				"https://www.pouet.net/prod.php?which=53509", 
				"https://media.demozoo.org/screens/o/08/a8/2c3d.118510.jpg"),
			new ExeGfx(
				" i felt the earth breathing"  ,"unc", 
				"https://demozoo.org/graphics/55042/", 
				"https://media.demozoo.org/screens/o/c4/03/bbc9.125264.jpg"),
		],
		2008: [
			new ExeGfx(
				"slicesix"  ,"iq", 
				"https://www.pouet.net/prod.php?which=51074", 
				"https://media.demozoo.org/screens/o/de/5a/8d69.117358.jpg"),
		],
		2007: [
			new ExeGfx(
				"Black Lines"  ,"Minas", 
				"https://demozoo.org/graphics/58998/", 
				"https://media.demozoo.org/screens/o/de/e4/ddc0.pl100230.png"),
		],

/*
		2015: [
			new ExeGfx("hold-and-modify", "flt & cncd", "https://www.pouet.net/prod.php?which=66097", "https://content.pouet.net/files/screenshots/00066/00066097.jpg"),
			new ExeGfx("emix"  ,"epoch ft. trio tampere", "https://www.pouet.net/prod.php?which=66066", "https://content.pouet.net/files/screenshots/00066/00066066.jpg"),
		],
		2014: [
			new ExeGfx("black and white lies"  ,"one studio off", "https://www.pouet.net/prod.php?which=63863", "https://content.pouet.net/files/screenshots/00063/00063863.jpg"),
			new ExeGfx("intrinsic gravity"  ,"still", "https://www.pouet.net/prod.php?which=64756", "https://content.pouet.net/files/screenshots/00064/00064756.jpg"),
		],
		2013: [
			
		],
		2012: [
			
		],
		2011: [
			
		],
		2010: [
			new ExeGfx("anoxia redux"  ,"asd & minimalartifact", "https://www.pouet.net/prod.php?which=55990", "https://content.pouet.net/files/screenshots/00055/00055990.png"),
		],
		2009: [
			
		],
		2008: [
			new ExeGfx("passing"  ,"still", "https://www.pouet.net/prod.php?which=51763", "https://content.pouet.net/files/screenshots/00051/00051763.jpg"),
			new ExeGfx(" hypnoise, part1: chipyxa#6 invitation"  ,"quite", "https://www.pouet.net/prod.php?which=52020", "https://content.pouet.net/files/screenshots/00052/00052020.jpg"),
		],
		2007: [
			
		],
		2006: [
			new ExeGfx("fr-055: 828"  ,"farbrausch", "https://www.pouet.net/prod.php?which=29739", "https://content.pouet.net/files/screenshots/00029/00029739.jpg"),
		],
		2005: [
			new ExeGfx("ocean machine"  ,"the black lotus", "https://www.pouet.net/prod.php?which=16337", "https://content.pouet.net/files/screenshots/00016/00016337.jpg"),
		],
		2004: [
			
		],
		2003: [
			
		],
		2002: [
			new ExeGfx("channel 5 sequence"  ,"haujobb", "https://www.pouet.net/prod.php?which=5591", "https://content.pouet.net/files/screenshots/00005/00005591.jpg"),
		],
		2001: [
			new ExeGfx("energia"  ,"sunflower", "https://www.pouet.net/prod.php?which=3290", "https://content.pouet.net/files/screenshots/00003/00003290.gif"),
			new ExeGfx("gerbera"  ,"moppi productions", "https://www.pouet.net/prod.php?which=3286", "https://content.pouet.net/files/screenshots/00003/00003286.jpg"),
		],
		2000: [
			new ExeGfx("incyber"  ,"satori & aural planet", "https://www.pouet.net/prod.php?which=1164", "https://content.pouet.net/files/screenshots/00001/00001164.jpg"),
		],
		1999: [
			new ExeGfx("codename chinadoll"  ,"katastro.fi", "https://www.pouet.net/prod.php?which=119", "https://content.pouet.net/files/screenshots/00000/00000119.jpg"),
			new ExeGfx("zilog"  ,"sunflower", "https://www.pouet.net/prod.php?which=294", "https://content.pouet.net/files/screenshots/00000/00000294.jpg"),
			new ExeGfx("fall equals winter"  ,"replay", "https://www.pouet.net/prod.php?which=501", "https://content.pouet.net/files/screenshots/00000/00000501.gif"),
		],
		1998: [
			new ExeGfx("te2-rb" ,"tpolm", "https://www.pouet.net/prod.php?which=117", "https://content.pouet.net/files/screenshots/00000/00000117.jpg"),
		],
		1997: [
			
		],
		1996: [
			
		] */

	}
	
	
	export let url = "/favdemos";

	onMount(async () => {
		document.addEventListener("pointermove", function(event) {
			hover_image_element.style.left = event.x + "px"
			hover_image_element.style.top = event.y + "px"
      // followMouse(image, event)
    });

	})

	onDestroy(() => {})
</script>

<style lang="scss">
	.year{
		font-weight: bolder;
		font-size: 2rem;
		margin-bottom: 0.1rem;
		margin-top: 0.9rem;
	}
	.demo{
		width: fit-content;
		display: flex;
		flex-direction: column;
		// align-items: center;
		// align-items: end;
		// text-decoration-style: dashed !important;
		// text-decoration-thickness: 0.2rem !important;
		// text-decoration: underline;
		// outline: bo;
		text-decoration: none !important;

		*{
			font-size: 1.0rem;
		}
		.name{
			// font-weight: bold;
			font-weight: var(--text-font-weight);
			font-size: 1.5rem !important;
			margin-bottom: 0.5rem;
			margin-top: 0.5rem;
			// a {
			// 	font-weight: var(--link-font-weight);
			// }
			// *{
			// 	font-weight: var(--text-font-weight);
			// 	font-size: 1.0rem;
			// }
		}
	}
	main {
		width: 100%;
		max-width: 40rem;
		min-height: 100%;
		height: fit-content;
		// overflow: scroll;
		display: flex;
		flex-direction: column;
		max-width: var(--main-max-width);
		canvas {
			display: none;
			width: 100%;
			height: 100%;
			// display: block;
			margin: auto;
			padding: 0;
		}
	}
</style>

