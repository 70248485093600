
<main>
	<Bar />
	<div>
	</div>
	{#each Object.keys(list).reverse() as year, i}
		<div class="year">
			{year}
		</div>
		{#each list[year] as demo, i}
			<a href={demo.link} target=”_blank” on:pointerenter={()=>showImage(demo)} on:pointerleave={()=>hideImage()} class="demo">
				<div class="name">
					{demo.name} &nbsp; — &nbsp; {demo.group}
				</div>
				<!-- <div class="name">
					{demo.name}
				</div>
				<div style="font-weight: 500;">
					&nbsp; — &nbsp;
				</div>
				<div class="group">
					{demo.group}
				</div> -->
			</a>
		{/each}
	{/each}
	<img 
		class="hover-image" 
		bind:this={hover_image_element} 
		style={`
			background:var(--accent-dark);
			display:none;
			position: fixed;
			pointer-events: none;
			position:fixed;
			min-width: 1rem;
			min-height: 1rem;
		`}
	/>
	<Footer />
</main>



<script lang="ts">
	import {onMount} from 'svelte'
	import {onDestroy} from 'svelte'

	import Bar from "./utils/NavBar.svelte"

	import Footer from './utils/Footer.svelte'
	
	let hover_image_element: HTMLImageElement
	
	function showImage(demo: Demo){
		hover_image_element.style.display = "block"
		hover_image_element.src = demo.img_url
	}
	function hideImage(){
		hover_image_element.style.display = "none"
	}
	
	class Demo{
		name: string
		group: string
		link: string
		img_url: string
		constructor(name: string, group: string, link: string, img_url: string = ""){
			this.name = name
			this.group = group 
			this.link = link
			this.img_url = img_url
		}
	}
	const list = {
		2024: [
			new Demo("Vernal Festival"  ,"Limp Ninja", "https://www.pouet.net/prod.php?which=97522", "https://media.demozoo.org/screens/o/b1/c0/ec30.348994.png"),
		],
		2023: [
			new Demo("0b5vr glsl techno live set"  ,"0b5vr", "https://www.pouet.net/prod.php?which=94135", "https://content.pouet.net/files/screenshots/00094/00094135.jpg"),
			new Demo("seven days"  ,"61Q0", "https://www.pouet.net/prod.php?which=91421", "https://content.pouet.net/files/screenshots/00091/00091421.jpg"),
			new Demo("katsumaki"  ,"still", "https://www.pouet.net/prod.php?which=95242", "https://content.pouet.net/files/screenshots/00095/00095242.jpg"),
		],
		2022: [
			new Demo("astrophage"  ,"logicoma", "https://www.pouet.net/prod.php?which=90874", "https://content.pouet.net/files/screenshots/00090/00090874.jpg"),
			new Demo("let this darkness be"  ,"Slay Bells", "https://www.pouet.net/prod.php?which=91356", "https://content.pouet.net/files/screenshots/00091/00091356.jpg"),
		],
		2021: [
			new Demo("there"  ,"still", "https://www.pouet.net/prod.php?which=88632", "https://content.pouet.net/files/screenshots/00088/00088632.jpg"),
			new Demo("concept"  ,"umlaut design", "https://www.pouet.net/prod.php?which=89840", "https://content.pouet.net/files/screenshots/00089/00089840.jpg"),
		],
		2020: [
			new Demo("bossa de cores"  ,"5711 & Farbrausch & Accession", "https://www.pouet.net/prod.php?which=86978", "https://content.pouet.net/files/screenshots/00086/00086978.jpg"),
			new Demo("wackelkontakt"  ,"Alcatraz & Prismbeings", "https://www.pouet.net/prod.php?which=85220", "https://content.pouet.net/files/screenshots/00085/00085220.png"),
			new Demo("la vie opportuniste"  ,"Razor1911", "https://www.pouet.net/prod.php?which=85221", "https://content.pouet.net/files/screenshots/00085/00085221.jpg"),
		],
		2019: [
			new Demo("terrarium"  ,"eos", "https://www.pouet.net/prod.php?which=82417", "https://content.pouet.net/files/screenshots/00082/00082417.jpg"),
			new Demo("zebrain"  ,"bC. & Tpolm", "https://www.pouet.net/prod.php?which=83691", "https://content.pouet.net/files/screenshots/00083/00083691.jpg"),
			new Demo("glitch rider"  ,"alcatraz", "https://www.pouet.net/prod.php?which=82612", "https://content.pouet.net/files/screenshots/00082/00082612.jpg"),
		],
		2018: [
			new Demo("until"  ,"fms_cat", "https://www.pouet.net/prod.php?which=79365", "https://content.pouet.net/files/screenshots/00079/00079365.jpg"),
			new Demo("dying stars"  ,"orange", "https://www.pouet.net/prod.php?which=77409", "https://content.pouet.net/files/screenshots/00077/00077409.jpg"),
			new Demo("zetsubo"  ,"prismbeings", "https://www.pouet.net/prod.php?which=75720", "https://content.pouet.net/files/screenshots/00075/00075720.png"),
		],
		2017: [
			new Demo("otaku"  ,"satori", "https://www.pouet.net/prod.php?which=71719", "https://content.pouet.net/files/screenshots/00071/00071719.gif"),
			new Demo("absolute territory"  ,"prismbeings", "https://www.pouet.net/prod.php?which=69642", "https://content.pouet.net/files/screenshots/00069/00069642.jpg"),
			new Demo("esocentrica"  ,"asd & satori", "https://www.pouet.net/prod.php?which=68795", "https://content.pouet.net/files/screenshots/00068/00068795.gif"),
		],
		2016: [
			new Demo("everyway"  ,"hoffman", "https://www.pouet.net/prod.php?which=67790", "https://content.pouet.net/files/screenshots/00067/00067790.png"),
		],
		2015: [
			new Demo("hold-and-modify", "flt & cncd", "https://www.pouet.net/prod.php?which=66097", "https://content.pouet.net/files/screenshots/00066/00066097.jpg"),
			new Demo("emix"  ,"epoch ft. trio tampere", "https://www.pouet.net/prod.php?which=66066", "https://content.pouet.net/files/screenshots/00066/00066066.jpg"),
		],
		2014: [
			new Demo("black and white lies"  ,"one studio off", "https://www.pouet.net/prod.php?which=63863", "https://content.pouet.net/files/screenshots/00063/00063863.jpg"),
			new Demo("intrinsic gravity"  ,"still", "https://www.pouet.net/prod.php?which=64756", "https://content.pouet.net/files/screenshots/00064/00064756.jpg"),
		],
		2013: [
			
		],
		2012: [
			
		],
		2011: [
			
		],
		2010: [
			new Demo("anoxia redux"  ,"asd & minimalartifact", "https://www.pouet.net/prod.php?which=55990", "https://content.pouet.net/files/screenshots/00055/00055990.png"),
		],
		2009: [
			
		],
		2008: [
			new Demo("passing"  ,"still", "https://www.pouet.net/prod.php?which=51763", "https://content.pouet.net/files/screenshots/00051/00051763.jpg"),
			new Demo(" hypnoise, part1: chipyxa#6 invitation"  ,"quite", "https://www.pouet.net/prod.php?which=52020", "https://content.pouet.net/files/screenshots/00052/00052020.jpg"),
		],
		2007: [
			
		],
		2006: [
			new Demo("fr-055: 828"  ,"farbrausch", "https://www.pouet.net/prod.php?which=29739", "https://content.pouet.net/files/screenshots/00029/00029739.jpg"),
		],
		2005: [
			new Demo("ocean machine"  ,"the black lotus", "https://www.pouet.net/prod.php?which=16337", "https://content.pouet.net/files/screenshots/00016/00016337.jpg"),
		],
		2004: [
			
		],
		2003: [
			
		],
		2002: [
			new Demo("channel 5 sequence"  ,"haujobb", "https://www.pouet.net/prod.php?which=5591", "https://content.pouet.net/files/screenshots/00005/00005591.jpg"),
		],
		2001: [
			new Demo("energia"  ,"sunflower", "https://www.pouet.net/prod.php?which=3290", "https://content.pouet.net/files/screenshots/00003/00003290.gif"),
			new Demo("gerbera"  ,"moppi productions", "https://www.pouet.net/prod.php?which=3286", "https://content.pouet.net/files/screenshots/00003/00003286.jpg"),
		],
		2000: [
			new Demo("incyber"  ,"satori & aural planet", "https://www.pouet.net/prod.php?which=1164", "https://content.pouet.net/files/screenshots/00001/00001164.jpg"),
		],
		1999: [
			new Demo("codename chinadoll"  ,"katastro.fi", "https://www.pouet.net/prod.php?which=119", "https://content.pouet.net/files/screenshots/00000/00000119.jpg"),
			new Demo("zilog"  ,"sunflower", "https://www.pouet.net/prod.php?which=294", "https://content.pouet.net/files/screenshots/00000/00000294.jpg"),
			new Demo("fall equals winter"  ,"replay", "https://www.pouet.net/prod.php?which=501", "https://content.pouet.net/files/screenshots/00000/00000501.gif"),
		],
		1998: [
			new Demo("te2-rb" ,"tpolm", "https://www.pouet.net/prod.php?which=117", "https://content.pouet.net/files/screenshots/00000/00000117.jpg"),
		],
		1997: [
			
		],
		1996: [
			
		]

	}
	
	
	export let url = "/favdemos";

	onMount(async () => {
		document.addEventListener("pointermove", function(event) {
			hover_image_element.style.left = event.x + "px"
			hover_image_element.style.top = event.y + "px"
      // followMouse(image, event)
    });

	})

	onDestroy(() => {})
</script>

<style lang="scss">
	.year{
		font-weight: bolder;
		font-size: 2rem;
		margin-bottom: 0.1rem;
		margin-top: 0.9rem;
	}
	.demo{
		width: fit-content;
		display: flex;
		// align-items: center;
		// align-items: end;
		// text-decoration-style: dashed !important;
		// text-decoration-thickness: 0.2rem !important;
		// text-decoration: underline;
		// outline: bo;

		*{
			font-size: 1.0rem;
		}
		.name{
			font-weight: bold;
		}
	}
	main {
		width: 100%;
		max-width: 40rem;
		min-height: 100%;
		height: fit-content;
		// overflow: scroll;
		display: flex;
		flex-direction: column;
		max-width: var(--main-max-width);
		canvas {
			display: none;
			width: 100%;
			height: 100%;
			// display: block;
			margin: auto;
			padding: 0;
		}
	}
</style>

