
<div 
  class="video-container"
 >
	<video 
    bind:this={video_element} 
    class="lozad" 
    data-src={`${ window.LFS_PREPEND }${src}`} loop={loop} autoplay={autoplay} muted={muted} controls={controls} 
    />
</div>
    
     

<script lang="ts">
	import {onDestroy, onMount} from 'svelte'
  // import play_pause_icon from "../../../public/play_pause.svg"
	// import image_a from "../../../public/images/generative_mograph/OUT (2).mp4"
  let video_element: HTMLVideoElement
  export let src: string = ""
  export let autoplay: boolean = true
  export let muted: boolean = true
  export let loop: boolean = true
  export let controls: boolean = false


	onDestroy(() => {})
	onMount(async () => {
    function handleVideoIntersection(entries, observer) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
        // if (entry.intersectionRatio > 0) {
          try{
            (entry.target as HTMLVideoElement).play().then(()=>{
              video_element.controls = false
            }).catch(e=>{
              
            })
          } catch(_){

          }
        } else {
        }
      });
    }
    
    if(autoplay){
      const observer = new IntersectionObserver(handleVideoIntersection, {  });
      observer.observe(video_element);
      video_element.addEventListener('loadeddata', function() {
        if(video_element.paused){
          video_element.controls = true
          video_element.parentElement!.onclick = ()=>{
            video_element.controls = false
            if(video_element.paused){
              video_element.play()
            }
          }
          // video_element.style = "display: none;"
          // let play_button = new HTMLDivElement()
          // play_button.style=`
          // `
          // video_element.parentElement!.querySelector(".play-button").style = `
          // 	background-image:url(http://png-4.findicons.com/files/icons/2315/default_icon/256/media_play_pause_resume.png);
          // 	background-repeat:no-repeat;
          // 	width:50%;
          // 	height:50%;
          // 	position:absolute;
          // 	left:0%;
          // 	right:0%;
          // 	top:0%;
          // 	bottom:0%;
          // 	margin:auto;
          // 	background-size:contain;
          // 	background-position: center;
          // `
        }
        // video_element.style = "margin-top: 100rem;"
        // setTimeout(()=>{
        // 	video_element.style = ""
        // }, 2000)
        // if(video_element.paused){
        // 	video_element.play()
        // }
      }, false);
    }
  })
</script>

<style lang="scss">
  .video-container{
    width: 100%;
    video{
      width: inherit;
    }
  }
</style>

