<script lang="ts">
  import Img from './lib/Img.svelte';
  import layout from './lib/layout';

  // import play_pause_icon from "../../public/play_pause.svg"
	import { onMount } from 'svelte'

  export let images: any[] = [];
  export let rowHeight = 220;
  export let gutter = 8;
  export let imageComponent = Img;
  export let hidden = false
  export let open_image_callback : (src: string)=>void

  let scaledImages: any[] = [];
  let width = 0;

  function imgStyle({ scaledWidth, scaledHeight, isLastInRow, isLastRow }) {
    let marginRight = gutter + 'px',
      flex = `0 0 ${scaledWidth}px`,
      marginBottom = isLastRow ? '0' : marginRight;

    if (isLastInRow) {
      marginRight = '0';
      flex = `1 1 ${scaledWidth - 4}px`;
    }

    return `height: ${scaledHeight}px; flex: ${flex}; margin-right: ${marginRight}; margin-bottom: ${marginBottom};`;
  }

  
  $: scaledImages = layout({
    images,
    containerWidth: width || 1280,
    targetHeight: rowHeight,
    gutter
  });
  $: console.log(scaledImages)
  // $: {
    // for (let img of images){
    //   console.log(img)
    // }
  // }
  let video_elements: HTMLVideoElement[] = []
  let video_element_parents: HTMLDivElement[] = []

	onMount(async () => {
    // Callback function to handle video intersection
    function handleVideoIntersection(entries, observer) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Play the video when it enters the viewport
          entry.target.play();
        } else {
          // Pause the video when it exits the viewport
          entry.target.pause();
        }
      });
    }

    // Create an Intersection Observer instance
    const observer = new IntersectionObserver(handleVideoIntersection, { threshold: 0.5 });

    // Observe each video element
    video_elements.forEach(video => {
      observer.observe(video);
    });
	})
</script>

<style>
  .masonry {
    max-width: 100%;
  }

  .container {
    display: flex;
    flex-wrap: wrap;
  }

  .image {
    position: relative;
    height: 100%;
  }

  .image > :global(*) {
    width: 100%;
    height: 100%;
  }

  .hidden {
    visibility: hidden;
  }
  .stashed {
    display: none;
  }

</style>

<div class="masonry" bind:clientWidth={width} class:stashed={hidden}>
  <div class="container" style="width: {width}px" class:hidden={!width}>
    {#each scaledImages as { index, ratio, scaledHeight, scaledWidth, isLastInRow, isLastRow, scaledWidthPc, ...image }, i}
      <!-- svelte-ignore a11y-no-static-element-interactions -->
      {#if image['is_video']}
        <div
          class="image"
          style={imgStyle({ scaledHeight, scaledWidth, isLastInRow, isLastRow })}
          bind:this={video_element_parents[i]}
        >
          <!-- on:click={()=>{open_image_callback(window.LFS_PREPEND + image.src)}} -->
            <slot {index} {image}>
              <!-- <div class="play-button" style={`
                  width: 4rem;
                  height: 4rem;
                  position: absolute;
                  top: 50%;
                  right: 50%;
                  transform: translate(50%, -50%);
                  pointer-events: none;
              `}>
                {@html play_pause_icon}
              </div> -->
              <video 
                bind:this={video_elements[i]}
                class="lozad" 
                data-src={
                  ((image['src_thumb'].length > 0) ? image['src_thumb'] : image.src)
                } 
                on:click={()=>{open_image_callback(image.src)}}
                loop
                muted
                width={image.width} height={image.height}
              />
            </slot>
        </div>
      {:else}
        <div
          class="image"
          style={imgStyle({ scaledHeight, scaledWidth, isLastInRow, isLastRow })}
          on:click={()=>{open_image_callback(image.src)}}
        >
            <slot {index} {image}>
              <img class="lozad" data-src={
                image['src_thumb'] ? image['src_thumb'] : image.src 
              } width={image.width} height={image.height}/>
            </slot>
        </div>
      {/if}
    {/each}
  </div>
</div>
