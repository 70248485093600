<svelte:head>
	{#if $dark_mode}
	<style>
		*{
		}
		/* *{
			--accent-light: white !important;
			--accent-dark: black !important;
		} */
		#toggle-container svg {
				background: var(--accent-light);
				fill:var(--accent-dark);
				filter: invert(1);
				&:active{
					filter: invert(0) !important;
					/* background: var(--accent-light); */
				}
			}
	</style>
	{/if}
</svelte:head>
<div style="width: 100%; height: 100%;">
	<main>
			<Bar />
			<Footer />
	</main>
	<canvas draggable="false" id="canvas" bind:this={canvasElement} />
</div>


<script lang="ts">
	import "./utils/wave-audio-path-player.es"
	import {onMount} from 'svelte'
	import {onDestroy} from 'svelte'

	import {resizeIfNeeded as resizeFramebuffersIfNeeded, init_gl_error_handling} from 'gl_utils'

	import { Framebuffer } from 'gl/Framebuffer'
	import { VertexBuffer, UBO } from 'gl/Buffer'
	import { Texture } from 'gl/Texture'
	import { ShaderProgram } from 'gl/ShaderProgram'
	import { Thing } from 'gl/Thing'
	
	import Bar from "./utils/NavBar.svelte"
	import Footer from './utils/Footer.svelte'

	
	import { Hash, lerp, min, smoothstep } from 'wmath'
	import { dark_mode } from "store"
	
	export let url = "/";
	// import Dexie, { type DBCoreRangeType } from "dexie"
	$: path_name = location.pathname 

	// GL stuff
	let canvasElement: HTMLCanvasElement
	let gl: WebGL2RenderingContext
	let default_framebuffer: Framebuffer
	let fb_a: Framebuffer
	let fb_b: Framebuffer
	let fb_c: Framebuffer
	let ubo: UBO

	let t: number = 0

	console.log(path_name)
	const init_web_gl = () => {
		window.gl = gl = canvasElement.getContext('webgl2', {
			preserveDrawingBuffer: false,
			alpha: false,
			premultipliedAlpha: false,
			antialias: true
		}) as WebGL2RenderingContext
		gl.getExtension('OES_texture_float');
		gl.getExtension('OES_texture_float_linear');
		gl.getExtension('EXT_color_buffer_float');


		gl.debugEnabled = process.env.NODE_ENV === 'development'
		gl.debugEnabled = false
		init_gl_error_handling()

		const user_agent_res = [canvasElement.clientWidth, canvasElement.clientWidth]

		default_framebuffer = Object.create(Framebuffer.prototype)
		default_framebuffer.default = true
		default_framebuffer.pongable = false
		default_framebuffer.needs_pong = false
		default_framebuffer.pong_idx = 0
		// @ts-ignore
		default_framebuffer._fb = null
		default_framebuffer._textures = [Object.create(Texture.prototype)]
		default_framebuffer.textures[0].res = [...user_agent_res]

		default_framebuffer.bind()

		fb_a = new Framebuffer([
			new Texture([...user_agent_res], gl.RGBA8) 
		], true)
		fb_b = new Framebuffer([
			new Texture([...user_agent_res], gl.RGBA8) 
		], false)
		fb_c = new Framebuffer([
			new Texture([...user_agent_res], gl.RGBA16F) 
		], true)
		


		ubo = new UBO()
		window.ubo = ubo
		

		resizeFramebuffersIfNeeded(canvasElement, default_framebuffer, user_agent_res)

		gl.disable(gl.CULL_FACE)
		gl.disable(gl.DEPTH_TEST)
		gl.enable(gl.BLEND)
		gl.blendFuncSeparate(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA, gl.ONE, gl.ONE_MINUS_SRC_ALPHA,);
		gl.blendEquationSeparate(gl.FUNC_ADD, gl.FUNC_ADD) 
		gl.clearColor(0,0,0,0);
		
		// gl.blendFuncSeparate(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA, gl.ONE, gl.ONE_MINUS_SRC_ALPHA,);
		// gl.blendEquationSeparate(gl.FUNC_ADD, gl.FUNC_ADD) 
	}

	onMount(async () => {
		init_web_gl()

		// default_framebuffer.bind()
		// default_framebuffer.clear([0, 0, 0, 1])
 
		// const blobs = new ShaderProgram("")
		const blobs_program = new ShaderProgram(require('shaders/fullscreen.vert'), require('shaders/blobs.frag'))
		const lines_program = new ShaderProgram(require('shaders/lines.vert'), require('shaders/lines.frag'))
		const feedback_program = new ShaderProgram(require('shaders/fullscreen.vert'), require('shaders/feedback.frag'))
		const post_program = new ShaderProgram(require('shaders/fullscreen.vert'), require('shaders/post_canvas.frag'))
		
		post_program.use()
		gl.uniform1i(gl.getUniformLocation(post_program.program, "fb_a"), 0)
		gl.uniform1i(gl.getUniformLocation(post_program.program, "fb_b"), 1)
		gl.uniform1i(gl.getUniformLocation(post_program.program, "noise_tex"), 2)

		blobs_program.use()
		gl.uniform1i(gl.getUniformLocation(blobs_program.program, "fb_a"), 0)
		gl.uniform1i(gl.getUniformLocation(blobs_program.program, "fb_b"), 1)

		feedback_program.use()
		gl.uniform1i(gl.getUniformLocation(feedback_program.program, "fb_a"), 0)
		gl.uniform1i(gl.getUniformLocation(feedback_program.program, "fb_b"), 1)


		const noise_tex = await Texture.from_image_path(window.LFS_PREPEND + require("../../public/images/noise_tex.webp").default)
		noise_tex.bind_to_unit(2)


		const lines_buff = new VertexBuffer(
			4, gl.FLOAT, 10000
		)
		// lines_buff.sz = lines_buff.cpu_buff.length
		const lines_thing = new Thing(
			[lines_buff], gl.TRIANGLES,lines_program
		)
		// gl.bindVertexArray(lines_buff.)


		const desired_mouse_pos = [0,0]
		const mouse_pos = [0,0]
		let pointer_pressure = 0.
		let pointer_down = false
		canvasElement.addEventListener("pointerdown", (e: PointerEvent) =>{
			e.stopPropagation()
		})
		window.addEventListener('pointerdown', (e: PointerEvent) => {
			pointer_down = true
		})
		window.addEventListener('pointerup', (e: PointerEvent) => {
			pointer_down = false
		})
		// @ts-ignore
		window.addEventListener('pointermove', (e: PointerEvent) => {
			const gl = window.gl

			// @ts-ignore
			const rect = gl.canvas.getBoundingClientRect()
			console.log(rect)


			// @ts-ignore
			let pos_x = ((e.clientX - rect.left) * gl.canvas.width) / gl.canvas.clientWidth
			// @ts-ignore
			let pos_y = ((e.clientY - rect.top) * gl.canvas.height) / gl.canvas.clientHeight

			const x = (pos_x / gl.canvas.width) * 2 - 1
			const y = (pos_y / gl.canvas.height) * -2 + 1

			desired_mouse_pos[0] = x
			desired_mouse_pos[1] = y
		})

		const set_shared_uniforms = () => {
			ubo.buff.sz = 0
			ubo.buff.cpu_buff[0] = canvasElement.width
			ubo.buff.cpu_buff[1] = canvasElement.height
			ubo.buff.cpu_buff[4] = t
			ubo.buff.cpu_buff[5] = pointer_pressure
			ubo.buff.cpu_buff[6] = mouse_pos[0]
			ubo.buff.cpu_buff[7] = mouse_pos[1] 
			ubo.buff.cpu_buff[8] = $dark_mode ? 1 : 0
			// ubo.buff.cpu_buff[2] = default_framebuffer.textures[0].res[0]
			// ubo.buff.cpu_buff[3] = default_framebuffer.textures[0].res[1]
			// ubo.buff.cpu_buff[4] = isOnMobile ? 1 : 0
			ubo.buff.upload()
		}

		let frame = 0

		const hash = new Hash()

		

		let delta_t: number = 0


		const gl_draw = (_t: number) => {
			const new_t = _t / 1000
			// delta_t = (new_t - t)*(mouse_pos[1]*0.5 + 0.5)*2
			delta_t = new_t - t
			t = new_t
			hash.seed = 125125
			mouse_pos[0] = lerp(mouse_pos[0],desired_mouse_pos[0], min(delta_t,1))
			mouse_pos[1] = lerp(mouse_pos[1],desired_mouse_pos[1], min(delta_t,1))
			
			if(pointer_down){
				pointer_pressure = lerp(pointer_pressure, 1.,min(delta_t*4,1))
			} else {
				pointer_pressure = lerp(pointer_pressure, 0.,min(delta_t*4,1))
			}
			
			
			const get_circ_pos_from_ang = (a: number) => {
				const c = Math.cos(-a)
				const s = Math.sin(-a)
				return [c, s]
			}
			const add_ang_to_pos = (
				pos: number[],
				ang_offs: number[],
				positive: boolean,
				amt: number,
			): number[] => {
				if (positive) {
					pos[0] += ang_offs[0] * amt
					pos[1] += ang_offs[1] * amt
				} else {
					pos[0] -= ang_offs[0] * amt
					pos[1] -= ang_offs[1] * amt
				}
				return pos
			}

			const positions = new Float32Array(lines_buff.cpu_buff.length / 3 + 1)

			for(let i = 0; i < positions.length/2 + 1; i++){
				const pos = [
					hash.valueNoise(i*0.26 + mouse_pos[0] + hash.valueNoise(t*0.25 + Math.sin(t*0.25))) * 2. - 1.,
					hash.valueNoise(i*0.26 + 12512 + hash.valueNoise(t*0.5)) * 2. - 1.0,
				]
				if(pointer_pressure > 0.002){
					const dir_vec_x = pos[0] - mouse_pos[0]
					const dir_vec_y = pos[1] - mouse_pos[1]
					const dir_vec_len = Math.sqrt(dir_vec_x * dir_vec_x + dir_vec_y * dir_vec_y)
					const dir_vec_factor = smoothstep(0.4,0.,dir_vec_len)
					// console.log(pointer_pressure)
					// pointer_pressure = 1
					pos[0] += dir_vec_factor * dir_vec_x * pointer_pressure * 4
					pos[1] += dir_vec_factor * dir_vec_y * pointer_pressure * 4
				}
				positions[i*2] = pos[0]
				positions[i*2 + 1] = pos[1]
			}
			
			const prev_pos = [0,0]
			for(let i = 0; i < lines_buff.cpu_buff.length / 3 / 4 / 2; i++){
				const step = 4 * 3 * 2
				let idx = i * step
				
				let curr_sz = hash.valueNoise(i)*0.04
				let next_sz = hash.valueNoise(i)*0.04

				let curr_ang = get_circ_pos_from_ang(0.4 + mouse_pos[1]*6)
				let next_ang = get_circ_pos_from_ang(0.2 + mouse_pos[1]*6)

				let curr_pos = [positions[i * 2], positions[i * 2 + 1]]
				let next_pos = [positions[i * 2 + 2], positions[i * 2 + 3]]

				let curr_pos_left = add_ang_to_pos([...curr_pos], curr_ang, true, curr_sz)
				let curr_pos_right = add_ang_to_pos([...curr_pos], curr_ang, false, curr_sz)

				let next_pos_left = add_ang_to_pos([...next_pos], next_ang, true, next_sz)
				let next_pos_right = add_ang_to_pos([...next_pos], next_ang, false, next_sz)

				lines_buff.cpu_buff[idx] = curr_pos_left[0];
				lines_buff.cpu_buff[idx + 1] = curr_pos_left[1];

				lines_buff.cpu_buff[idx + 2] = 0;
				lines_buff.cpu_buff[idx + 3] = 0;

				lines_buff.cpu_buff[idx + 4] = curr_pos_right[0];
				lines_buff.cpu_buff[idx + 4 + 1] = curr_pos_right[1];
				lines_buff.cpu_buff[idx + 4 + 2] = 1;
				lines_buff.cpu_buff[idx + 4 + 3] = 0;

				lines_buff.cpu_buff[idx + 8] = next_pos_left[0];
				lines_buff.cpu_buff[idx + 8 + 1] = next_pos_left[1];
				lines_buff.cpu_buff[idx + 8 + 2] = 0;
				lines_buff.cpu_buff[idx + 8 + 3] = 1;

				idx += 12

				lines_buff.cpu_buff[idx] = next_pos_left[0];
				lines_buff.cpu_buff[idx + 1] = next_pos_left[1];
				lines_buff.cpu_buff[idx + 2] = 0;
				lines_buff.cpu_buff[idx + 3] = 1;

				lines_buff.cpu_buff[idx + 4] = curr_pos_right[0];
				lines_buff.cpu_buff[idx + 4 + 1] = curr_pos_right[1];
				lines_buff.cpu_buff[idx + 4 + 2] = 1;
				lines_buff.cpu_buff[idx + 4 + 3] = 0;

				const mid_p_x = lerp(next_pos_left[0], curr_pos_right[0], 0.5)
				const mid_p_y = lerp(next_pos_left[1], curr_pos_right[1], 0.5)
				lines_buff.cpu_buff[idx + 8] = lerp(next_pos_right[0], mid_p_x, 0.9);
				lines_buff.cpu_buff[idx + 8 + 1] = lerp(next_pos_right[1], mid_p_y, 0.9);
				lines_buff.cpu_buff[idx + 8 + 2] = 1;
				lines_buff.cpu_buff[idx + 8 + 3] = 1;

				// lines_buff.cpu_buff[i*4] = pos[0];
				// lines_buff.cpu_buff[i*4 + 1] = pos[1];
			}
			lines_buff.upload()
			
			set_shared_uniforms()
			resizeFramebuffersIfNeeded(
				canvasElement, 
				default_framebuffer, 
				default_framebuffer._textures[0].res, 
			)

			gl.viewport(0,0,canvasElement.width, canvasElement.height)

			gl.bindFramebuffer(gl.FRAMEBUFFER, fb_a.fb)
			gl.clear(gl.COLOR_BUFFER_BIT)
			gl.useProgram(blobs_program.program)
			fb_a.back_textures[0].bind_to_unit(0)
			fb_c.textures[0].bind_to_unit(1)
			gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4)

			gl.bindFramebuffer(gl.FRAMEBUFFER, fb_b.fb)
			gl.clear(gl.COLOR_BUFFER_BIT)
			lines_thing.draw()

			gl.bindFramebuffer(gl.FRAMEBUFFER, fb_c.fb)
			gl.clear(gl.COLOR_BUFFER_BIT)
			gl.useProgram(feedback_program.program)
			fb_c.back_textures[0].bind_to_unit(0)
			fb_b.textures[0].bind_to_unit(1)
			gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4)
			
			
			gl.bindFramebuffer(gl.FRAMEBUFFER, null)
			gl.clear(gl.COLOR_BUFFER_BIT)
			gl.useProgram(post_program.program)
			fb_a.textures[0].bind_to_unit(0)
			fb_b.textures[0].bind_to_unit(1)
			gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4)


			frame++
			for (let framebuffer of Framebuffer.framebuffers) {
				if (framebuffer.pongable) {
					framebuffer.pong()
				}
			}
			requestAnimationFrame(gl_draw)
		}
		;(() => {
			gl_draw(0)
		})()
	})

	onDestroy(() => {})
</script>

<style lang="scss">

	// :global(*:not(#name)){
	main :global(*){
		user-select: none;
	}
	// }
	main :global(*) {
		color: var(--accent-dark);
		&::selection {
			background: var(--accent-dark);
			color: var(--accent-light);
		}
		// #logo,.menu{
	}
	// main :global(.menu-item){
	// 	color: var(--accent-light);
	// 	background: var(--accent-dark);
	// 	&:hover{
	// 		color: var(--accent-dark) !important;
	// 		background: var(--accent-light);
	// 	}
	// }
	// :global(.menu-item)>div>div{
	// 	// color: red !important;
	// 	// background: red;
	// 	// color: var(--accent-light);
	// 	// background: var(--accent-dark);
	// 	// &:hover{
	// 	// 	color: var(--accent-dark) !important;
	// 	// 	background: var(--accent-light);
	// 	// }
		
	// }
	main :global(#logo){
		color: var(--accent-light);
		background: var(--accent-dark);
		// color: red;
		// background: black;
	}
	main {
		width: 100%;
		max-width: 40rem;
		height: 100%;
		display: flex;
		flex-direction: column;
		max-width: var(--main-max-width);
		margin-right: auto;
		margin-left: auto;
	}
	canvas {
		position: absolute;
		top: 0;
    left: 0;
		z-index: -5;
		// display: none;
		width: 100%;
		height: 100%;
		// display: block;
		margin: auto;
		padding: 0;
	}
</style>
