
<div class="video-container">
  <div>
    <iframe title="Youtube video" src={`https://www.youtube.com/embed/${src}?rel=0&modestbranding=1`} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
</div>
    
     

<script lang="ts">
  
  export let src: string = ""
  // import play_pause_icon from "../../../public/play_pause.svg"
	// import image_a from "../../../public/images/generative_mograph/OUT (2).mp4"
</script>

<style lang="scss">
  .video-container{
    width: inherit;
  }

</style>

