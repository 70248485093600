<svelte:head>
	{#if flex_wrapped}
	<style>
#bar-right{
	width: 100%;
}
#toggle-container{
	margin-left: 0px;
	margin-right: auto;
}
	</style>
	{:else}
	<style>
	</style>
	{/if}
</svelte:head>
<nav id="bar" bind:this={parent_element}>
	<div id="bar-left" bind:this={left_element}>
		<div id="logo-container">
			<a href="/" class="logo logo-behind logo-behind-a {hovered_class}">
				<mark>
				wrighter
				</mark>
			</a>
			<a href="/" class="logo logo-behind logo-behind-b {hovered_class}">
				wrighter
			</a>
			<a href="/" class="logo {hovered_class}" bind:this={logo_element}>
				wrighter
			</a>
		</div>
		{#if !flex_wrapped}
			<DarkModeToggle/>
		{/if}
	</div>
	<div id="bar-right" bind:this={right_element}>
		{#if flex_wrapped}
			<DarkModeToggle/>
		{/if}
		<div id="menus" bind:this={menus_element}>
			<div id="menu" >
				<slot />
				<!-- <Link link="/art" text="/art"> </Link>
				<Link link="/music" text="/music"> </Link>
				<Link link="/favdemos" text="/fav <br> demos"> </Link>
				<Link link="/projects" text='/projects'> </Link>
				<Link link="/about" text="/about"> </Link> -->
			</div>
			<div id="menu" >
				<Link link="/art" text="/art"> </Link>
				<Link link="/music" text="/music"> </Link>
				<Link link="/favdemos" text="<div><div>/fav</div> <div>demos</div></div>"> </Link>
				<Link link="/favexegfx" text="<div><div>/fav</div> <div>exegfx</div></div>"> </Link>
				<Link link="/blog" text='/blog'> </Link>
				<Link link="/projects" text='/projects'> </Link>
				<Link link="/about" text="/about"> </Link>
			</div>
		</div>
	</div>
</nav>


<script lang="ts">
	import {onMount} from 'svelte'
	import {onDestroy} from 'svelte'
	import Link from "./Link.svelte"
	
	import DarkModeToggle from './DarkModeToggle.svelte';
	
	// let logo_hovered: boolean = false
	
	let logo_element: HTMLElement
	
	let right_element: HTMLElement;
	let left_element: HTMLElement;
	let parent_element: HTMLElement;

	let menus_element: HTMLElement;
	
	let flex_wrapped = false
	
	function check_flex_wrapped(){
		if(parent_element.clientWidth < menus_element.clientWidth + left_element.clientWidth){
			flex_wrapped = true
		} else {
			flex_wrapped = false
		}
	}
	
	// $: hovered_class = logo_hovered ? "hovered" : ""
	let hovered_class = ""

	// hovered_class = "hovered"
	// hovered_class += " hover-effect-" + 1
	
	let effect_idx = 0
	
	let last_t_during_fx_change = new Date()
	
	

	onMount(async () => {
		check_flex_wrapped()
		window.addEventListener('resize', ()=>{
			check_flex_wrapped()
		})

		logo_element.addEventListener('mouseenter', ()=>{
			let new_date = new Date()
			let seconds = Math.abs(last_t_during_fx_change.getTime() - new_date.getTime())/1000;

			// logo_hovered = true
			hovered_class = "hovered"
			if(seconds > 0.5){
				hovered_class += " hover-effect-" + effect_idx
			}

			console.log("hovered")
		})
		logo_element.addEventListener('mouseleave', ()=>{
			hovered_class = ""
			if(!navigator.userAgent.includes("Mozilla")){
				effect_idx = (effect_idx + 1) % 2
			}

			// effect_idx = 1
			// logo_hovered = false
		})
	})

	onDestroy(() => {})
</script>

<style lang="scss">
#bar {
		width: 100%;
		margin-top: 1rem;
		margin-bottom: 1rem;
		// padding-right: 5rem;
		// padding-left: 5rem;
		display: flex;
		flex-wrap: wrap;
		// margin-left: 10rem;
		// margin-right: auto;
		// .logo-behind-a{
		// 	background: black !important;
		// }
		#bar-left{
			display: flex;
			flex-direction: column;
			// height: 11rem;
		}
		mark{
			background: var(--accent-dark);
		}
		.logo{
			&.logo-behind{
				position:absolute;
			}
			// &:not(.logo-behind):hover{
			// 	background: black;
			// }
			$base-duration: 300ms;
			font-size: 4rem;
			margin-left: 1rem;
			height: fit-content;

			// Colors
			$color-1: #ff5500;
			$color-2: #450f8b;
			$color-3: #bfcc2e;
			$black: var(--accent-dark);
			text-decoration: none;
			&.hovered{
				color: var(--accent-dark) !important;
				// background: none;

				&.hover-effect-0{
					@keyframes distort1 {
						0%    { transform: translate(.5rem, .5rem); }
						12.5% { transform: translate(.5rem,  0rem); }
						25%   { transform: translate(.5rem,  1.5rem); }
						37.5% { transform: translate(0rem,  .5rem); }
						50%   { transform: translate(.5rem,  0.5rem); }
						62.5% { transform: translate(.5rem,  0rem); }
						75%   { transform: translate(.5rem,  .5rem); }
						87.5% { transform: translate(0rem,  .5rem); }
						100%  { transform: translate(1.5rem,  .5rem); }
					}
					
					@keyframes distort2 {
						0%    { transform: translate(0.5rem,  0.5rem); }
						12.5% { transform: translate(0rem,  0.5rem); }
						25%   { transform: translate(1.5rem,  0.5rem); }
						37.5% { transform: translate(1.5rem,  0rem); }
						50%   { transform: translate(1.5rem,  .5rem); }
						62.5% { transform: translate(0rem,  1.5rem); }
						75%   { transform: translate(0.5rem,  .5rem); }
						87.5% { transform: translate(0.5rem,  0rem); }
						100%  { transform: translate(0.5rem,  0.5rem); }
					}

					@keyframes glitch-anim3 {
						$steps: 50;
						@for $i from 0 to $steps {
							#{percentage($i*(1/$steps))} {
								clip: rect(random(40)/10 + rem, 9999px, random(10)/2 + rem, 0);
								// transform: skew((random(100) / 5) + deg);
								transform-origin: center;
								// z-index: random(4);
							}
						}
					}
					@keyframes cooool {
						$stepss: 50;
						@for $k from 0 to $stepss {
							#{percentage($k*(1/$stepss))} {
								// clip: rect(random(40)/10 + rem, 9999px, random(10)/2 + rem, 0);
								// color: oklch(100%, 1.0, 21.57) !important;
								color: pink !important;
								// transform: skew((random(100) / 5) + deg);
								// transform-origin: center;
								// z-index: random(4);
							}
						}
					}

					animation: glitch-anim3 10s infinite linear alternate-reverse;
					&.logo-behind-a {
						color: $color-1 !important;
						animation: distort1 $base-duration linear infinite,
							glitch-anim3 5s infinite linear alternate-reverse,
							cooool 5s infinite linear alternate-reverse 
							// col-anim 5s infinite linear infinite
							;
					}

					&.logo-behind-b {
						// top: 50.5%;
						// left: 50.5%;
						color: rgba($color-2,1.0) !important;
						// transform: scale(1.4);
						animation: distort2 $base-duration linear infinite,
							glitch-anim3 3s infinite linear alternate-reverse;
					}

				}
			}
			&.hover-effect-1{
				animation: glitch-skew 1s infinite linear alternate-reverse;
				@keyframes glitch-anim {
					$steps: 50;
					@for $i from 0 to $steps {
						#{percentage($i*(1/$steps))} {
							clip: rect(random(40)/10 + rem, 9999px, random(10)/2 + rem, 0);
							transform: skew((random(100) / 10) + deg);
							z-index: random(4);
						}
					}
				}

				@keyframes glitch-anim2 {
					$steps: 150;
					@for $i from 0 to $steps {
						#{percentage($i*(1/$steps))} {
							clip: rect(random(10)/50 + rem, 99999px, random(100)/10 + rem, 0);
						}
					}
				}

				@keyframes glitch-skew {
					$steps: 10;
					@for $i from 0 to $steps {
						#{percentage($i*(1/$steps))} {
							transform: skew((random(10) - 5) + deg);
						}
					}
				}
				&.logo-behind-a {
					// top: 49.5%;
					// left: 49.5%;
					// z-index: 1;
					color: var(--accent-light);
					transform: translateX(-0.5rem);
					color: rgba($color-1,1.0) !important;
					text-shadow: -2px 0 rgba($color-1,1.0), 2px 2px rgba($color-1,1.0);
					clip: rect(44px, 450px, 56px, 0);
					animation: glitch-anim2 5s infinite linear alternate-reverse;
				}

				&.logo-behind-b {
					// top: 50.5%;
					// left: 50.5%;
					color: $color-3 !important;
					z-index: 1;
					transform: translateX(0.2rem);
					// transform: scale(1.4);
					text-shadow: -2px 0 rgba($color-2,1.0);
					clip: rect(44px, 450px, 56px, 0);
					animation: glitch-anim 5s infinite linear alternate-reverse;
				}
				
			}
		
			&.logo-behind{
				width: 0px;
				height: 0px;
				overflow: visible;
				&:not(.hovered){
					display: none;
				}
				// transition: transform 2s;
				// z-index: 1;
				pointer-events: none;
			}
			

			// display:inline;
		}

		#bar-right{
			margin-left: auto;
			display: flex;
			margin-right: 1rem;
			margin-top: 1rem;
			position: relative;
			#menus{
				display: flex;
				#menu{
					height: 10rem;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					margin-right: 1rem;
					:global(.menu-item){
						font-weight: 750;
						font-style: italic;
						width: fit-content;
					}
					
				}
			}

		}

		width: 100%;
	}
	canvas {
		display: none;
		width: 100%;
		height: 100%;
		// display: block;
		margin: auto;
		padding: 0;
	}
</style>
