<!-- OpenGraph image: https://media.githubusercontent.com/media/wrightwriter/wrightwriter.github.io/master/images/generative_static/unknown%20(4).png -->
<!-- OpenGraph description: Using GPU atomics to render flame fractals and IFSs. -->


<BlogTitle title={title} date={date} />

<article>

<brb />
<brb />  

## **Introduction**


[Flame fractals](https://en.wikipedia.org/wiki/Fractal_flame) are generative systems for producing mesmerizing images with intricate details. 
They were introduced by [Scott Draves](https://en.wikipedia.org/wiki/Scott_Draves) in a [paper](https://flam3.com/flame_draves.pdf) back in 1992. 
It's an extension of [Iterated Function System fractals](https://maths-people.anu.edu.au/~john/Assets/Research%20Papers/fractals_self-similarity.pdf), discovered in [1981 by John E Hutchinson](https://maths-people.anu.edu.au/~john/Assets/Research%20Papers/fractals_self-similarity.pdf).
   
   
There's a thriving community of fractal artists utilizing a variety of 
[sof](https://github.com/bezo97/IFSRenderer)[tw](https://www.chaoticafractals.com)[are](https://github.com/3Dickulus/FragM) to generate them.
<brb />
<brb />
    
<Image src={`/blog/vorpol_dawn_by_technochroma.jpg`} />
<SubTitle>
  <a href="https://www.deviantart.com/technochroma/art/Vorpol-Dawn-714213981">Vorpol Dawn</a> by <a href="https://www.technochroma.art">technochroma</a>
</SubTitle>

<brb />


One of my favourite fractal pieces is the realtime [demo](https://en.wikipedia.org/wiki/Demoscene) [Terrarium](https://www.pouet.net/groups.php?which=12701). It measures at a filesize of only 4kb and you can run it locally [here](https://files.scene.org/view/parties/2019/assembly19/4k/terrarium_by_eos.zip).


<brb />

<wbr />

<YoutubeVideo src={`xLN3mTRlugs`}/>

<SubTitle>
  <a href="https://www.pouet.net/prod.php?which=82417">Terrarium</a> by <a href="https://www.pouet.net/groups.php?which=12701">Eos</a>
</SubTitle>
<brb />
<brb />

I learned the algorithm by reading the demo's [source code](https://github.com/demoscene-source-archive/terrarium/blob/master/src/shaders/fragment.glsl).

Fundamentally quite simple and beautiful, the gist of it is:
  - create 2 images - one you are accumulating to(density map) and one you are displaying
  - write some transformations f\(p\). can be anything
  - generate a bunch of points 
  - infinitely, for each point
    - pick a random transformation
    - run the point through it and set its value to the output.  p = f(p)
    - add 1 to the pixel of the density accumulation image where the point landed
  - display by applying a tonemap and writing to the output image


It's explained excellently in the following video: 
<brb />
<brb />

<YoutubeVideo src={`kbKtFN71Lfs`}/>
<brb />
<brb />

Flame fractals are pretty much [chaos game](https://en.wikipedia.org/wiki/Chaos_game), but with arbitrary transforms. Chaos game is a method of rendering [IFSs](https://en.wikipedia.org/wiki/Iterated_function_system).
The other ways flame fractals differ from chaos game are:
  - [Tonemapping](https://en.wikipedia.org/wiki/Tone_mapping) is done through a log-density function.
  - Colouring is done by "sliding" an index into a colour palette, depending on which transform gets picked in each recursive iteration.  

This is explained in the flame fractal [paper](https://flam3.com/flame_draves.pdf), but I'll showcase a simplified "creative coding"-friendly method, where you can make your own colouring algorithms.
  
<brb />

## **Splatting**

<brb />

```glsl
layout(r32ui) uniform coherent restrict writeonly uimage2D density_image;

vec2 project_particle(vec3 p, out float particle_depth){
	// Insert camera transformation and projection here. 
	// This is basic perspective projection.
	p.xy /= p.z;
	particle_depth = p.z;
	return p.xy;
}

void main(){
	int particle_idx = int(gl_GlobalInvocationID.x) 
		+ int(gl_GlobalInvocationID.y)*int(gl_NumWorkGroups.x * gl_WorkGroupSize.x);
  
	const int max_iters_per_particle = 100;
  
	// Please use an int hash :) Float hashes suck...
	hash_seed = int_hash(particle_idx);
  
	// Random starting pos, can be anything.
	vec3 p = random_vec3();

	for(int i=0; i<max_iters_per_particle; i++){
		
		// Random variable
		float r = random_float();

		// Pick a transformation
		if(r<.3){
			p += 0.3;
			p.xz *= rot(T );
			p /= clamp(dot(p,p),-0.2,4.);
		} else if(r<.66){
			p.xz *= rot(5.2+ sin(float(particle_idx)*0.00001)*0.001);
			p.yz *= rot(5.2);
			p += vec3(-1.,0.4,0.);
			p /= clamp(dot(-p,p),-3.2,1.);
			p *= vec3(2,1.5,1.2)*1.5;
		}
    else {
			p -= vec3(-0.2,0.2,0.2);
			p /= clamp(dot(p,p),-4.5,10.);
			p *= vec3(2,1.5,1.2)*3.1;
		}
		
		
		// Splat the particle
		float particle_depth;
		vec2 part_uv = project_particle(particle_depth);

		// Disable depth rejection for trippiness :)
		if(particle_depth > 0.0){
			// Would ideally use a tent filter or mitchell-netravalli/lancozs
			vec2 anti_aliasing = random_point_in_disk()/min(resolution.x, resolution.y); 
			ivec2 int_p = ivec2(part_uv + anti_aliasing)*ivec2(resolution);
			if(
				// Inside bounds
				int_p.x >= 0 && int_p.x < int(resolution.x) &&
				int_p.y >= 0 && int_p.y < int(resolution.y)
			) { 
				imageAtomicAdd(density_image, int_p, 1);
			}
		}
	}
}
```
   
<brb />

```glsl
imageAtomicAdd(density_image, int_p, 1);
```

"image[Atomic](https://en.wikipedia.org/wiki/Linearizability)Add" lets multiple threads write/read a location without [data racing](https://en.wikipedia.org/wiki/Race_condition).

<brb />
  

```glsl
layout(r32ui) uniform coherent restrict writeonly uimage2D i_flameCounter;
```
- "coherent" is needed when different [shader invocations](https://www.khronos.org/opengl/wiki/Shader#Execution_and_invocations) 
(single pixel in a frag shader, single thread in a compute shader) write/read to locations potentially read/written from other invocations. 
It enforces [coherent memory access](https://www.khronos.org/opengl/wiki/Memory_Model#Ensuring_visibility). It can look cool if you don't enable it :)
    
- "restrict" is a hint to the compiler that you will only use a single variable to read from the memory buffer and you won't use another varaible to read the first one. This leads to performance optimizations.
    
- "writeonly" doesn't let you read from the memory. I'm not sure if it leads to any optimizations.
   
More info on the [khronos wiki](https://www.khronos.org/opengl/wiki/Type_Qualifier_(GLSL)).

<brb />

## **Transformations**

<brb />

```glsl
if(r<.3){
	p += 0.3;
	p.xz *= rot(T );
	p /= clamp(dot(p,p),-0.2,4.);
} else if(r<.66){
	p.xz *= rot(5.2+ sin(float(particle_idx)*0.00001)*0.001);
	p.yz *= rot(5.2);
	p += vec3(-1.,0.4,0.);
	p /= clamp(dot(-p,p),-3.2,1.);
	p *= vec3(2,1.5,1.2)*1.5;
}
else {
	p -= vec3(-0.2,0.2,0.2);
	p /= clamp(dot(p,p),-4.5,10.);
	p *= vec3(2,1.5,1.2)*3.1;
}
```
<brb />

What about the transformations?  
  
They are totally arbitrary, but what's being done here is [affine](https://en.wikipedia.org/wiki/Affine_transformation) transformations + sphere inversion.   
  
[Circle inversion](https://en.wikipedia.org/wiki/Inversive_geometry) is `p /= dot(p,p);` and is a case of the [Möbius transform](https://en.wikipedia.org/wiki/M%C3%B6bius_transformation).
It's very commonly used in fractals. Sphere inversion is its extension to 3D. It's a [conformal](https://en.wikipedia.org/wiki/Conformal_map) mapping, which means it preserves angles.

<brb />

<YoutubeVideo src={`IAPf1ZYfm-s`}/>

<brb />

[IFSRenderer](https://github.com/bezo97/IFSRenderer)'s [Transforms repo](https://github.com/bezo97/IFSTransforms/tree/3ad08c70a1149dbc946baca68708a3beb05f2e9f/Transforms) is a cool source of transforms written in glsl.
<brb />

## **Rendering + colouring**
Flame fractals, as per Scott Draves's [paper](https://flam3.com/flame_draves.pdf), are coloured by sliding each particle over a palette, while it's being transformed. In the end, it's post processed using a log-density function.
  
I won't explain that in this article.
  
An easy way to post process is by scaling brightness with the reciprocal of the total splats you did, then applying an [HDR->LDR tonemap](https://64.github.io/tonemapping/).  
  
Here I use Inigo Quilez's palette function, then multiply it by the scaled density and apply the [Reinhard tonemap](https://www-old.cs.utah.edu/docs/techreports/2002/pdf/UUCS-02-001.pdf). 
Reinhard tonemap is really not ideal if you want a nice filmic-looking image, in big part (but not limited to) because it doesn't desaturate bright regions. That might not be what you want in generative art though. 
<brb />

```glsl
layout(r32ui) uniform coherent restrict uimage2D density_image;
uniform int total_particle_splats_count;
uniform float brightness;
out vec4 out_colour;

vec3 pallete(float k){
	// Simple variation of https://iquilezles.org/articles/palettes/
	// You can use anything here.
	return 0.5 + 0.5 * sin(vec3(3,2,1) + k);
}

void main(){
	uint density = imageLoad(density_image, uvec2(gl_FragCoord));

	float density_scaled = float(density) / total_particle;
	density_scaled *= brightness;

	vec3 colour = pallete(density_scaled);
	colour *= density_scaled;
	colour = colour / (1. + colour);

	out_colour = vec4(colour, 1.);
}

```
## **Depth of field**
[Terrarium](https://www.pouet.net/prod.php?which=82417) complements the beautiful abstract shapes with depth of field. 
That is easy to achieve by offsetting the position by a disk (or any other distribution) when splatting.

<brb />

```glsl
const float focus_dist = 0.7;
const float dof_scale = 1.0;
vec2 dof_sample = dof_scale * get_random_point_in_disk() * (depth - focus_dist) / min(resolution.x, resolution.y);
vec2 anti_aliasing = random_point_in_disk() / min(resolution.x, resolution.y); 
ivec2 int_p = ivec2(uv + anti_aliasing + dof_sample)*ivec2(resolution);
```

<brb />

## **Motion blur**
Also seen in [Terrarium](https://www.pouet.net/prod.php?which=82417), motion blur can be done by dithering the time variable when you use it inside transformation functions. 
Motion blur is usually used as a technique to antialias over time, but you can tweak its kernel size for artistic effect.



<brb />

## **Wrapping up**

<brb />

Thanks for reading and I'd love to see what you make!  

<brb />

Here are some of my own artworks:

<brb />

<Image src="/images/generative_static/image (29).png" />

<brb />

<Image src="/images/generative_static/image (26).png" />

<brb />

<Image src="/images/generative_static/unknown (4).png" />

<brb />

<Image src="/images/generative_static/unknown (5).png" />

<brb />

<Image src="/images/generative_static/image (25).png" />

<brb />

<Video src="/images/generative_mograph/chrome_WqJeO3gecm.mp4" autoplay={false} controls={true}/>

<brb />

<Video src="/images/generative_mograph/vivaldi_r0PEmo85Q3.mp4" autoplay={false} controls={true}/>

<brb />

<Video src="/images/generative_mograph/chrome_yM83fEWe71.mp4" autoplay={false} controls={true}/>

<brb />

<Video src="/images/generative_mograph/chrome_Wb6pBvRRgB.mp4" autoplay={false} controls={true}/>

<brb />

<Video src="/images/generative_mograph/chrome_c2bM8EGIdS.mp4" autoplay={false} controls={true}/>

<brb />

<Video src="/images/generative_mograph/chrome_4pKmSCfcQD.mp4" autoplay={false} controls={true}/>

<brb />

</article>

<script lang="ts">
	import {onDestroy} from 'svelte'
	import { onMount } from "svelte"
	import Video from "../utils/Video.svelte"
	import Image from "../utils/Image.svelte"
	import SubTitle from "../utils/SubTitle.svelte"
	import BlogTitle from "../utils/BlogTitle.svelte"
  
  export let date = new Date("2023-08-17")
  export let title = "Rendering flame fractals with a compute shader"
  
	import YoutubeVideo from "components/utils/YoutubeVideo.svelte"

	onDestroy(() => {})
	onMount(async () => {
	})
</script>

<style lang="scss">

</style>

